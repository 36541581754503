import { firestore } from "../../firebase";
import {
  IGroupSchieberSettings,
  IGroupCoiffeurSettings,
} from "../domain/group-settings";

export class SettingsService {
  static async setOrUpdateSchieberSettings(
    settings: Partial<IGroupSchieberSettings>,
    groupId: string
  ) {
    const docRef = firestore
      .collection("groups")
      .doc(`${groupId}`)
      .collection("settings")
      .doc("schieber");
    const doc = await docRef.get();
    if (doc.exists) {
      await docRef.update(settings);
    } else {
      await docRef.set(settings);
    }
  }

  static async setOrUpdateCoiffeurSettings(
    settings: Partial<IGroupCoiffeurSettings>,
    groupId: string
  ) {
    const docRef = firestore
      .collection("groups")
      .doc(`${groupId}`)
      .collection("settings")
      .doc("coiffeur");
    const doc = await docRef.get();
    if (doc.exists) {
      await docRef.update(settings);
    } else {
      await docRef.set(settings);
    }
  }

  static async updateGroupName(name: string, groupId: string) {
    const docRef = firestore.collection("groups").doc(`${groupId}`);
    await docRef.update({
      name,
    });
  }
}
