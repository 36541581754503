import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { collect, store } from "react-recollect";
import { IDiscipline } from "../../api/domain/discipline";
import { Page } from "../../components/page/page";
import { DisciplineAverageStatistic } from "../../components/statistics/discipline-average-statistic/discipline-average-statistic";
import { TotalCostsStatistics } from "../../components/statistics/total-costs-statistics/total-costs-statistics";
import { useGroups } from "../../hooks/use-groups";
import { useUser } from "../../hooks/use-user";
import "./statistics-page.scss";
import { MatchesPerTeamStatistics } from "../../components/statistics/matches-per-team-statistics/matches-per-team-statistics";
import { CoiffeurPerTeamStatistics } from "../../components/statistics/coiffeur-per-team-statistics/coiffeur-per-team-statistics";

interface IOwnProps {
    className?: string;
}

interface IStatisticsPageProps extends IOwnProps {}

const InternalStatisticsPage: React.FC<IStatisticsPageProps> = ({ className }) => {
    const [user] = useUser();
    const [groups] = useGroups();
    const defaultGroupId = user?.defaultGroupId;
    const selectedGroup = useMemo(
        () => (groups.length === 1 ? groups[0] : groups.find(({ uid }) => uid === defaultGroupId)),
        [defaultGroupId, groups]
    );

    return (
        <Page title="Statistik">
            <div className="px-3">
                {!!selectedGroup && (
                    <Row>
                        <Col xs={12} md={6} lg={4}>
                            <TotalCostsStatistics groupId={selectedGroup.uid} />
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <CoiffeurPerTeamStatistics groupId={selectedGroup.uid} />
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <MatchesPerTeamStatistics groupId={selectedGroup.uid} />
                        </Col>
                        {store.disciplines.map((discipline: IDiscipline) => (
                            <Col key={discipline.uid} xs={12} md={6} lg={4}>
                                <DisciplineAverageStatistic groupId={selectedGroup.uid} discipline={discipline} />
                            </Col>
                        ))}
                    </Row>
                )}
            </div>
        </Page>
    );
};

export const StatisticsPage = collect(InternalStatisticsPage);
