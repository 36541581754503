import firebase from "firebase";
import { firestore } from "../../firebase";
import { IMarks } from "../domain/marks";
import { SchieberService } from "./schieber-service";

export class MarksService {
    static getUnpaidMarksQuery = (): firebase.firestore.Query => {
        const uid = firebase.auth().currentUser?.uid;
        return firestore
            .collectionGroup("marks")
            .where("userId", "==", uid)
            .where(`payed`, "==", false)
            .orderBy("date", "desc");
    };

    static getPaidMarksQuery = (): firebase.firestore.Query => {
        const uid = firebase.auth().currentUser?.uid;
        return firestore
            .collectionGroup("marks")
            .where("userId", "==", uid)
            .where(`payed`, "==", true)
            .orderBy("date", "desc");
    };

    static async addSchieberMark(groupId: string, schieberId: string, userIds: string[]) {
        await this.editSchieberMark(groupId, schieberId, userIds, 1);
    }
    static async removeSchieberMark(groupId: string, schieberId: string, userIds: string[]) {
        await this.editSchieberMark(groupId, schieberId, userIds, -1);
    }

    static async editSchieberMark(groupId: string, schieberId: string, userIds: string[], delta: number) {
        const settings = await SchieberService.getGroupSchieberSettings(groupId);
        let batch = firestore.batch();
        for (let i = 0; i < userIds.length; i++) {
            const userId = userIds[i];
            const marksRef = firestore
                .collection("games")
                .doc(groupId)
                .collection("schieber")
                .doc(schieberId)
                .collection("marks")
                .doc(userId);
            const marksDoc = await marksRef.get();
            const marksOld: IMarks = marksDoc.data() as IMarks;
            const totalMarks = Math.max(0, (marksOld?.totalMarks || 0) + delta);
            const date = firebase.firestore.Timestamp.now();
            const costsPerMark = settings?.costsPerMark || 0;
            const totalCosts = costsPerMark * totalMarks;
            const marksNew: IMarks = {
                totalMarks,
                type: "schieber",
                totalCosts,
                costsPerMark,
                userId,
                payed: false,
                date,
                gameId: schieberId,
            };
            batch.set(marksRef, marksNew);
        }
        await batch.commit();
    }
}
