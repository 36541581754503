import React, { useState } from "react";
import { Button } from "react-onsenui";
import { useHistory } from "react-router";
import { IUser } from "../../api/domain";
import { IResolvedGroup } from "../../api/domain/group";
import { Page } from "../../components/page/page";
import { TeamConfigurator } from "../../components/team-configurator/team-configurator";
import { createSchieber } from "../../store/updaters/createSchieber";
import "./create-schieber-page.scss";

interface IOwnProps {
    className?: string;
}

interface ICreateSchieberPageProps extends IOwnProps {}

const isTeamComplete = (team?: IUser[]) => team && team.length === 2;
const teamsComplete = (teams?: IUser[][]) => teams && teams.length === 2 && teams.every(isTeamComplete);

export const CreateSchieberPage: React.FC<ICreateSchieberPageProps> = ({ className }) => {
    const history = useHistory();
    const [group, setGroup] = useState<IResolvedGroup>();
    const [teams, setTeams] = useState<IUser[][]>([]);
    const onBack = () => history.push("/");

    const onTeamSave = (group: IResolvedGroup, teams: IUser[][]) => {
        setGroup(group);
        setTeams(teams);
    };

    const startSchieber = async () => {
        if (!group || !teamsComplete(teams)) {
            return;
        }
        const id = await createSchieber(group, teams);
        history.push(`/schieber/${id}`);
    };

    return (
        <Page swipeable={false} onBack={onBack} title="Schieber starten">
            <div>
                <TeamConfigurator teams={teams} onSave={onTeamSave} />
            </div>
            <div className="p-5"></div>
            <div className="p-4 fixed-bottom w-100">
                <Button onClick={startSchieber} disabled={!teamsComplete(teams)} modifier="large--cta">
                    Schieber starten
                </Button>
            </div>
        </Page>
    );
};
