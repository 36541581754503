import React from "react";
import { Alert } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { Button, Icon, List, ListHeader, ListItem } from "react-onsenui";
import { collect, WithStoreProp } from "react-recollect";
import { useHistory } from "react-router";
import { IResolvedGroup } from "../../api/domain/group";
import { GroupBox } from "../../components/group-box/group-box";
import { Page } from "../../components/page/page";
import { firebase } from "../../firebase";
import { fetchGroups } from "../../store/updaters/fetchGroups";
import "./groups-page.scss";

interface IOwnProps {}

interface IGroupsPageProps extends IOwnProps, WithStoreProp {}

const InternalGroupsPage: React.FC<IGroupsPageProps> = ({ store }) => {
    const history = useHistory();
    const [firebaseUser] = useAuthState(firebase.auth());
    const createdGroups = store.groups.filter((group) => group.creator === firebaseUser?.uid);
    const joinedGroups = store.groups.filter((group) => group.creator !== firebaseUser?.uid);

    return (
        <Page onUpdate={() => fetchGroups()} title="Jassgruppen">
            <List>
                <ListHeader>Meine Jassgruppen</ListHeader>
                {createdGroups.map((group: IResolvedGroup, idx) => (
                    <GroupBox group={group} key={idx} />
                ))}
                {createdGroups.length === 0 && <ListItem>Du hast noch keine Jassgruppe erstellt</ListItem>}
                <ListHeader>Zugehörige Jassgruppen anderer</ListHeader>
                {joinedGroups.map((group: IResolvedGroup, idx) => (
                    <GroupBox group={group} key={idx} />
                ))}
                {joinedGroups.length === 0 && <ListItem>Du bist noch zu Jassgruppe hinzugefügt worden</ListItem>}
                <ListHeader>Aktionen</ListHeader>
                {createdGroups.length >= 3 && (
                    <ListItem>
                        <Alert variant="info" className="d-flex">
                            <Icon icon="md-info" />
                            <div className="pl-3">Du kannst maximal 3 Jassgruppen erstellen</div>
                        </Alert>
                    </ListItem>
                )}
                <ListItem>
                    <Button
                        disabled={createdGroups.length >= 3}
                        onClick={() => history.push("/neue-jassgruppe")}
                        modifier="large--cta"
                    >
                        Jassgruppe erstellen
                    </Button>
                </ListItem>
            </List>
        </Page>
    );
};

export const GroupsPage = collect(InternalGroupsPage);
