import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Card } from "react-onsenui";
import { firebase } from "../../../firebase";
import "./user-card.scss";

interface IOwnProps {
  className?: string;
}

interface IUserCardProps extends IOwnProps {}

export const UserCard: React.FC<IUserCardProps> = ({ className }) => {
  const [firebaseUser] = useAuthState(firebase.auth());

  return (
    <Card>
      <div className="font-weight-bold text-center pb-3">Angemeldet als:</div>
      <div className="d-flex flex-column">
        <picture className="rounded d-flex justify-content-center align-content-center">
          {firebaseUser?.photoURL && (
            <img
              alt="Benutzerbild"
              className="profile-image rounded-circle"
              src={firebaseUser.photoURL}
            />
          )}
        </picture>
        <div className="mt-3">
          <h3 className="m-0 text-primary text-center">
            {firebaseUser?.displayName}
          </h3>
          <p className="mt-2 text-secondary text-center">
            {firebaseUser?.email}
          </p>
        </div>
      </div>
    </Card>
  );
};
