import { useCallback, useEffect, useState } from "react";
import { ISchieber } from "../api/domain/schieber";
import { ITeam } from "../api/domain/team";
import { SchieberService } from "../api/service/schieber-service";
import { addSchieberMark } from "../store/updaters/addSchieberMark";
import { removeSchieberMark } from "../store/updaters/removeSchieberMark";
import { setSchieberWinner } from "../store/updaters/setSchieberWinner";

export type IUseSchieber = [
    ISchieber | undefined,
    boolean,
    any,
    (userIds: string[]) => Promise<void>,
    (userIds: string[]) => Promise<void>,
    (team?: ITeam) => Promise<void>
];

export const useSchieber = (schieberId?: string): IUseSchieber => {
    const [schieber, setSchieber] = useState<ISchieber>();
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(false);
    const groupId = schieber?.groupId;

    const setWinner = useCallback(
        async (team?: ITeam) => {
            if (!schieberId || !groupId) {
                return;
            }
            setLoading(true);
            await setSchieberWinner({ groupId, schieberId, team });
            await updateSchieber(schieberId);
            setLoading(false);
        },
        [schieberId, groupId]
    );
    const addMark = useCallback(
        async (userIds: string[]) => {
            if (!schieberId || !groupId) {
                return;
            }
            setLoading(true);
            await addSchieberMark(groupId, schieberId, userIds);
            setLoading(false);
        },
        [schieberId, groupId]
    );
    const removeMark = useCallback(
        async (userIds: string[]) => {
            if (!schieberId || !groupId) {
                return;
            }
            setLoading(true);
            await removeSchieberMark(groupId, schieberId, userIds);
            setLoading(false);
        },
        [schieberId, groupId]
    );
    const updateSchieber = (schieberId: string) =>
        SchieberService.getSchieber(schieberId)
            .then((schieber) => setSchieber(schieber))
            .catch((e) => setError(e))
            .finally(setLoading.bind(null, false));

    useEffect(() => {
        if (schieberId) {
            setLoading(true);
            updateSchieber(schieberId);
        }
    }, [schieberId]);

    return [schieber, loading, error, addMark, removeMark, setWinner];
};
