import classNames from "classnames";
import React from "react";
import { IDisciplineConfiguration } from "../../../api/domain/coiffeur-configuration";
import { DisciplineImage, DisciplineImageSize } from "../../discipline-image/discipline-image";
import "./slate-discipline.scss";

interface IOwnProps {
    className?: string;
    discipline: IDisciplineConfiguration;
}

interface ISlateDisciplineProps extends IOwnProps {}

export const SlateDiscipline: React.FC<ISlateDisciplineProps> = ({ className, discipline }) => {
    return (
        <div
            className={classNames(
                className,
                "slate-discipline d-flex align-items-center justify-content-center position-relative"
            )}
        >
            <div className="slate-discipline__label d-flex text-white align-items-center font-chalk-label px-1 justify-content-around w-100">
                {discipline.multiplier}
                <DisciplineImage disciplineId={discipline.disciplineId} size={DisciplineImageSize.EXTRA_SMALL} />
            </div>
            {/*<Popover content={true}*/}
            {/*         className="position-absolute" placement="right" id={discipline.disciplineId} title="test">*/}
            {/*    {discipline.name}*/}
            {/*    <DisciplineImage inverted={true} disciplineId={discipline.disciplineId}*/}
            {/*                     size={DisciplineImageSize.EXTRA_SMALL}/>*/}
            {/*</Popover>*/}
        </div>
    );
};
