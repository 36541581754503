import React from "react";
import { Button, Icon, List, ListHeader, ListItem } from "react-onsenui";
import { useHistory } from "react-router";
import { IResolvedGroup } from "../../api/domain/group";
import { UserListItem } from "../list-items/user-list-item/user-list-item";
import "./group-box.scss";

interface IOwnProps {
  group: IResolvedGroup;
}

interface IGroupBoxProps extends IOwnProps {}

const InternalGroupBox: React.FC<IGroupBoxProps> = ({ group }) => {
  const history = useHistory();
  return (
    <ListItem expandable={true}>
      {group.name}
      <div className="expandable-content">
        <List>
          <ListHeader className="d-flex justify-content-center">
            <Button
              onClick={() =>
                history.push(`/jassgruppe-einstellungen/${group.uid}`)
              }
            >
              <Icon icon="md-settings" />
              <span className="pl-3">Einstellungen</span>
            </Button>
          </ListHeader>
          {group.members.map((member) => (
            <UserListItem key={member.uid} user={member} />
          ))}
        </List>
      </div>
    </ListItem>
  );
};

export const GroupBox = InternalGroupBox;
