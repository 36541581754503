import { MarksService } from "../../api/service/marks-service";
import { NotificationService } from "../../api/service/notification-service";

export const removeSchieberMark = async (groupId: string, schieberId: string, userIds: string[]) => {
    try {
        await MarksService.removeSchieberMark(groupId, schieberId, userIds);
        NotificationService.successTop("Strich gelöscht");
    } catch (e) {
        NotificationService.errorTop("Strich konnte nicht gelöscht werden");
    }
};
