import React from "react";
import "./coiffeur-log-modal.scss";
import { List, ListTitle, Modal, Page } from "react-onsenui";
import { Button } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { StoredLogEvent } from "../../api/domain";
import { LogListItem } from "./log-list-item/log-list-item";

interface IOwnProps {
    className?: string;
    isOpen?: boolean;
    onClose: () => void;
    coiffeurId: string;
    groupId: string;
}

interface ICoiffeurLogModalProps extends IOwnProps {}

export const CoiffeurLogModal: React.FC<ICoiffeurLogModalProps> = ({ isOpen, onClose, coiffeurId, groupId }) => {
    const [logs] = useCollectionData<StoredLogEvent>(CoiffeurService.getCoiffeurLogsRef(groupId, coiffeurId), {
        idField: "uid",
    });
    return (
        <Modal isOpen={isOpen}>
            <Page>
                <ListTitle>Coiffeur Log</ListTitle>
                <List
                    dataSource={logs}
                    renderRow={(logEvent) => <LogListItem logEvent={logEvent} key={logEvent.uid} />}
                />
                <div className="p-3 fixed-bottom d-flex">
                    <Button onClick={() => onClose()} className="font-chalk-label font-weight bold flex-grow-0 ml-auto">
                        Schliessen
                    </Button>
                </div>
            </Page>
        </Modal>
    );
};
