import classNames from "classnames";
import React, { useMemo } from "react";
import { IUser } from "../../api/domain";
import { ISchieber } from "../../api/domain/schieber";
import { ITeam } from "../../api/domain/team";
import { SchieberPlayer } from "../schieber-player/schieber-player";
import "./schieber-team.scss";
import { Button } from "react-bootstrap";

interface IOwnProps {
    className?: string;
    team: ITeam;
    labelPosition: "top" | "bottom";
    schieber: ISchieber;
    addMark: (userIds: string[]) => Promise<void>;
    removeMark: (userIds: string[]) => Promise<void>;
    setWinner: (team: ITeam) => Promise<void>;
}

interface ISchieberTeamProps extends IOwnProps {}

export const SchieberTeam: React.FC<ISchieberTeamProps> = ({
    setWinner,
    className,
    addMark,
    schieber,
    team,
    labelPosition,
    removeMark,
}) => {
    const users: IUser[] = Object.keys(team.members).map((memberId) => team.members[memberId]);

    const onWinnerClick = async (e: React.MouseEvent) => {
        e.stopPropagation();
        await setWinner(team);
    };
    const winners = useMemo(
        () => users.every(({ uid }) => schieber.winners && schieber.winners[uid]),
        // eslint-disable-next-line
        [schieber, users, schieber.winners]
    );

    return (
        <div className={classNames(className, "schieber-team")}>
            {labelPosition === "top" && (
                <Button
                    onClick={onWinnerClick}
                    className={classNames(
                        "schieber-team__win-button font-crayon w-100",
                        winners && "schieber-team__win-button--won"
                    )}
                >
                    Gewonnen
                </Button>
            )}
            <div className="p-4 d-flex justify-content-around">
                {users.map((user) => (
                    <SchieberPlayer
                        addMark={addMark}
                        removeMark={removeMark}
                        groupId={schieber.groupId}
                        schieberId={schieber.uid}
                        key={user.uid}
                        labelPosition={labelPosition}
                        user={user}
                    />
                ))}
            </div>
            {labelPosition === "bottom" && (
                <Button
                    onClick={onWinnerClick}
                    className={classNames(
                        "schieber-team__win-button font-crayon w-100",
                        winners && "schieber-team__win-button--won"
                    )}
                >
                    Gewonnen
                </Button>
            )}
        </div>
    );
};
