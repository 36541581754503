import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button as BootstrapButton, Button, ButtonGroup, Container } from "react-bootstrap";
import { LazyList, Modal } from "react-onsenui";
import { ICoiffeurWithTeams } from "../../api/domain/coiffeur";
import { ISchieberWithTeams } from "../../api/domain/schieber";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { SchieberService } from "../../api/service/schieber-service";
import { CoiffeurListItem } from "../../components/list-items/coiffeur-list-item/coiffeur-list-item";
import { SchieberListItem } from "../../components/list-items/schieber-list-item/schieber-list-item";
import { Page } from "../../components/page/page";
import { deleteCoiffeur } from "../../store/updaters/deleteCoiffeur";
import { deleteSchieber } from "../../store/updaters/deleteSchieber";
import "./all-games-page.scss";

interface IOwnProps {
    className?: string;
}

interface IAllGamesPageProps extends IOwnProps {}

const pageSize = 2;
export const AllGamesPage: React.FC<IAllGamesPageProps> = ({ className }) => {
    const [type, setType] = useState("coiffeur");
    const [coiffeurGames, setCoiffeurGames] = useState<ICoiffeurWithTeams[]>([]);
    const [schieberGames, setSchieberGames] = useState<ISchieberWithTeams[]>([]);
    const isCoiffeur = type === "coiffeur";
    const isSchieber = !isCoiffeur;
    const [endReached, setEndReached] = useState(false);
    useEffect(() => {
        if (isCoiffeur && !coiffeurGames.length) {
            fetchMoreCoiffeurs();
        } else if (isSchieber && !schieberGames.length) {
            fetchMoreSchiebers();
        }
        setEndReached(false);
        // eslint-disable-next-line
    }, [isCoiffeur, isSchieber]);
    const loadMoreGames = () => {
        if (isCoiffeur) {
            fetchMoreCoiffeurs();
        } else {
            fetchMoreSchiebers();
        }
    };
    const fetchMoreCoiffeurs = async (reset?: boolean) => {
        const latestFetchedCoiffeur = !reset ? coiffeurGames[coiffeurGames.length - 1] : undefined;
        const latestCoiffeurGames = await CoiffeurService.getCoiffeurWithTeams(
            pageSize,
            latestFetchedCoiffeur && latestFetchedCoiffeur.date
        );
        if (latestCoiffeurGames.length < pageSize) {
            setEndReached(true);
        }
        setCoiffeurGames(!reset ? coiffeurGames.concat(latestCoiffeurGames) : latestCoiffeurGames);
    };
    const fetchMoreSchiebers = async (reset?: boolean) => {
        const latestFetchedSchieber = !reset ? schieberGames[schieberGames.length - 1] : undefined;
        const latestSchieberGames = await SchieberService.getSchieberWithTeams(
            pageSize,
            latestFetchedSchieber && latestFetchedSchieber.date
        );
        if (latestSchieberGames.length < pageSize) {
            setEndReached(true);
        }
        setSchieberGames(!reset ? schieberGames.concat(latestSchieberGames) : latestSchieberGames);
    };
    useEffect(() => {
        fetchMoreCoiffeurs();
        // eslint-disable-next-line
    }, []);
    const [toBeDeleted, setToBeDeleted] = useState<string>();
    const requestDelete = (gameId: string) => setToBeDeleted(gameId);
    const deleteGame = async (gameId?: string) => {
        if (!gameId) {
            return;
        }
        setToBeDeleted(undefined);
        if (isCoiffeur) {
            await deleteCoiffeur(gameId);
            await fetchMoreCoiffeurs(true);
        } else {
            await deleteSchieber(gameId);
            await fetchMoreSchiebers(true);
        }
    };

    const renderGames = (index: number) => {
        if (isCoiffeur) {
            const coiffeur = coiffeurGames[index];
            return <CoiffeurListItem key={coiffeur.uid} deleteCoiffeur={requestDelete} coiffeur={coiffeur} />;
        } else {
            const schieber = schieberGames[index];
            return <SchieberListItem key={schieber.uid} deleteSchieber={requestDelete} schieber={schieber} />;
        }
    };

    return (
        <Page
            onUpdate={() => fetchMoreCoiffeurs(true)}
            title="Alle Spiele"
            className={classNames(className, "all-games-page")}
        >
            <div className="d-flex justify-content-center align-items-center p-3">
                <ButtonGroup aria-label="Basic example">
                    <Button
                        onClick={setType.bind(null, "coiffeur")}
                        // @ts-ignore
                        variant={classNames(!isCoiffeur ? "outline-secondary" : "primary")}
                        className="p-4"
                    >
                        Coiffeur
                    </Button>
                    <Button
                        onClick={setType.bind(null, "schieber")}
                        // @ts-ignore
                        variant={classNames(!isSchieber ? "outline-secondary" : "primary")}
                        className="p-4"
                    >
                        Schieber
                    </Button>
                </ButtonGroup>
            </div>
            <div className="border-top border-bottom border-2 bg-wood d-flex justify-content-center">
                <LazyList
                    className="bg-wood game-list"
                    length={isCoiffeur ? coiffeurGames.length : schieberGames.length}
                    renderRow={renderGames}
                    calculateItemHeight={() => 44}
                />
            </div>

            <div className="p-4 d-flex justify-content-center align-items-center">
                <Button onClick={loadMoreGames} disabled={endReached} variant="outline-primary">
                    Mehr Spiele laden
                </Button>
            </div>
            <Modal isOpen={!!toBeDeleted}>
                <Container className="font-crayon text-1-3">
                    Willst du diesen Coiffeur wirklich löschen?
                    <br />
                    Achtung! Dies ist endgültig und kann nicht rückgängig gemacht werden.
                </Container>
                <div className="p-3 fixed-bottom d-flex">
                    <BootstrapButton
                        onClick={setToBeDeleted.bind(null, undefined)}
                        variant="success"
                        className="font-chalk-label font-weight bold flex-grow-0"
                    >
                        Abbrechen
                    </BootstrapButton>
                    <BootstrapButton
                        onClick={deleteGame.bind(null, toBeDeleted)}
                        variant="danger"
                        className="font-chalk-label font-weight bold py-4 flex-grow-1 ml-4"
                    >
                        Löschen
                    </BootstrapButton>
                </div>
            </Modal>
        </Page>
    );
};
