import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import {
  Button,
  Checkbox,
  Icon,
  List,
  ListItem,
  ProgressBar,
  SearchInput,
} from "react-onsenui";
import { useHistory } from "react-router";
import { IUser } from "../../../api/domain";
import { Page } from "../../../components/page/page";
import {
  UserImage,
  UserImageSize,
} from "../../../components/user-image/user-image";
import { firebase, firestore } from "../../../firebase";
import { createGroup } from "../../../store/updaters/createGroup";
import "./create-group-page.scss";

interface IOwnProps {}

interface ICreateGroupPageProps extends IOwnProps {}

export const CreateGroupPage: React.FC<ICreateGroupPageProps> = () => {
  const [groupName, setGroupName] = useState("");
  const [creating, setCreating] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [members, setMembers] = useState([] as IUser[]);
  const [firebaseUser] = useAuthState(firebase.auth());
  const history = useHistory();
  const goBack = () => history.push("/jassgruppen");
  const [users, loadingUsers] = useCollectionDataOnce<IUser>(
    firestore
      .collection("users")
      .where("displayName", ">=", searchText)
      .where("displayName", "<=", searchText + "\uf8ff")
      .limit(3)
  );
  const toggleMember = (user: IUser) =>
    user.uid !== firebaseUser?.uid &&
    setMembers(
      members.some((m) => m.uid === user.uid)
        ? members.filter((m) => m.uid !== user.uid)
        : [...members, user]
    );
  const onCreate = async () => {
    setCreating(true);
    await createGroup(
      groupName,
      members.map((member) => member.uid)
    );
    goBack();
    setCreating(false);
  };

  return (
    <Page title="Neue Jassgruppe" onBack={goBack}>
      <div className="py-2" />
      <Container className="d-flex justify-content-end">
        <Button
          onClick={onCreate}
          disabled={members.length < 3 || !groupName || creating}
        >
          Erstellen
        </Button>
      </Container>
      <Form.Group>
        <div className="py-2" />
        <Container>
          <Form.Label className="font-weight-bold">Gruppenname:</Form.Label>
          <Form.Control
            type="text"
            maxLength={25}
            value={groupName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length > 25) {
                return;
              }
              setGroupName(event.target.value);
            }}
            placeholder="Gruppenname"
          />
          <Form.Text className="text-muted">Der Name der Gruppe.</Form.Text>
        </Container>
        <div className="py-2" />
        <Container>
          <Form.Label className="font-weight-bold">
            Mitglieder einladen:
          </Form.Label>
          <Form.Text className="text-muted">
            mind. 3 weitere Mitglieder
          </Form.Text>
          <div className="py-2" />
          <SearchInput
            value={searchText}
            // @ts-ignore
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.length > 25) {
                return;
              }
              setSearchText(event.target.value);
            }}
            placeholder="Name"
          />
          <div className="py-2" />
        </Container>
        <div style={{ maxHeight: 200 }} className="overflow-auto">
          <List>
            {loadingUsers && <ProgressBar indeterminate={true} />}
            {searchText &&
              !loadingUsers &&
              users &&
              users.map((user) => (
                <ListItem
                  key={user.uid}
                  onClick={toggleMember.bind(null, user)}
                >
                  <div className="left">
                    <Checkbox
                      checked={
                        members.some((m) => m.uid === user.uid) ||
                        user.uid === firebaseUser?.uid
                      }
                    />
                  </div>
                  <div className="center text-truncate">{user.displayName}</div>
                  <div className="right">
                    <UserImage
                      user={user}
                      size={UserImageSize.EXTRA_SMALL}
                      rounded={true}
                    />
                  </div>
                </ListItem>
              ))}
          </List>
        </div>
        <div className="py-2" />
        <Container>
          <Form.Label className="font-weight-bold">Mitglieder:</Form.Label>
          <div className="py-2" />
        </Container>
        <List>
          {members.map((user) => (
            <ListItem
              className="w-100"
              key={user.uid}
              onClick={toggleMember.bind(null, user)}
            >
              <div className="left">
                <Icon icon="md-close" />
              </div>
              <div className="center text-truncate">{user.displayName}</div>
              <div className="right">
                <UserImage
                  user={user}
                  size={UserImageSize.EXTRA_SMALL}
                  rounded={true}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </Form.Group>
    </Page>
  );
};
