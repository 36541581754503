import classNames from "classnames";
import React, { useState } from "react";
import { Button as BootstrapButton, Container } from "react-bootstrap";
import { useCollectionData, useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { AlertDialog, Button, Modal } from "react-onsenui";
import { IUser } from "../../api/domain";
import { ICoiffeur } from "../../api/domain/coiffeur";
import { IDisciplineConfiguration } from "../../api/domain/coiffeur-configuration";
import { IResult } from "../../api/domain/result";
import { ITeam } from "../../api/domain/team";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { AddCoiffeurResultPopup } from "../add-coiffeur-result-popup/add-coiffeur-result-popup";
import { ProgressIndicator } from "../progress-indicator/progress-indicator";
import "./coiffeur-slate.scss";
import { SlateCategoryColumn } from "./slate-category-column/slate-category-column";
import { SlateResultColumn, TeamPosition } from "./slate-result-column/slate-result-column";

interface IOwnProps {
    className?: string;
    coiffeur: ICoiffeur;
    addResult: (
        teamId: string,
        opponentTeamId: string,
        { player, match, disciplineId, counterMatch, points }: IResult
    ) => void;
    deleteResult: (teamId: string, opponentTeamId: string, disciplineId: string) => void;
}

interface ICoiffeurSlateProps extends IOwnProps {}

export const CoiffeurSlate: React.FC<ICoiffeurSlateProps> = ({ className, coiffeur, addResult, deleteResult }) => {
    const [disciplines, loadingDisciplines] = useCollectionDataOnce<IDisciplineConfiguration>(
        CoiffeurService.getCoiffeurConfigurationRef(coiffeur.groupId, coiffeur.uid),
        {
            idField: "disciplineId",
        }
    );
    const [editingDiscipline, setEditingDiscipline] = useState<IDisciplineConfiguration>();
    const [editingTeam, setEditingTeam] = useState<ITeam>();
    const [action, setAction] = useState<string>();
    const [teams, loadingTeams] = useCollectionData<ITeam>(
        CoiffeurService.getCoiffeurTeamsRef(coiffeur.groupId, coiffeur.uid),
        {
            idField: "uid",
        }
    );
    const [blocked, setBlocked] = useState(coiffeur.completed);
    const [showBlockedMessage, setShowBlockedMessage] = useState(false);
    const disciplineAction = (team: ITeam, discipline: IDisciplineConfiguration, resultExists: boolean) => {
        if (coiffeur.completed && blocked) {
            setShowBlockedMessage(true);
            return;
        }
        if (resultExists) {
            setAction("delete");
        } else {
            setAction("add");
        }
        setEditingTeam(team);
        setEditingDiscipline(discipline);
    };
    const onAddResult = (
        points: number,
        { disciplineId, multiplier }: IDisciplineConfiguration,
        team: ITeam,
        player: IUser,
        match: boolean,
        counterMatch: boolean
    ) => {
        const opponent = teams?.find((t) => t.uid !== team.uid);
        if (!opponent) {
            return;
        }
        setEditingDiscipline(undefined);
        addResult(team.uid, opponent.uid, {
            points,
            multiplier,
            counterMatch,
            disciplineId,
            match,
            player,
        });
    };
    const onDeleteResult = () => {
        if (!editingTeam || !editingDiscipline) {
            return;
        }
        const opponent = teams?.find((t) => t.uid !== editingTeam.uid);
        if (!opponent) {
            return;
        }
        deleteResult(editingTeam.uid, opponent.uid, editingDiscipline.disciplineId);
        setEditingDiscipline(undefined);
    };

    return (
        <div className="w-100 h-100 coiffeur-slate-wrapper d-flex justify-content-center bg-wood">
            <div className={classNames(className, "coiffeur-slate w-100 h-100 bg-slate")}>
                {!loadingDisciplines && !loadingTeams && teams && disciplines && teams.length === 2 ? (
                    <div className="coiffeur-slate__column-wrapper d-flex flex-nowrap">
                        <SlateCategoryColumn
                            disciplines={disciplines}
                            coiffeur={coiffeur}
                            className="coiffeur-slate__column border-right border-white border-2 flex-grow-0"
                        />
                        <SlateResultColumn
                            position={TeamPosition.LEFT}
                            disciplineAction={disciplineAction}
                            disciplines={disciplines}
                            coiffeur={coiffeur}
                            team={teams[0]}
                            className="coiffeur-slate__column border-right border-white border-3 flex-grow-0"
                        />
                        <SlateResultColumn
                            position={TeamPosition.RIGHT}
                            disciplineAction={disciplineAction}
                            disciplines={disciplines}
                            coiffeur={coiffeur}
                            team={teams[1]}
                            className="coiffeur-slate__column flex-grow-0"
                        />
                    </div>
                ) : (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <ProgressIndicator />
                    </div>
                )}
            </div>
            {editingDiscipline && editingTeam && action === "add" && (
                <AddCoiffeurResultPopup
                    team={editingTeam}
                    coiffeur={coiffeur}
                    discipline={editingDiscipline}
                    isOpen={!!editingDiscipline}
                    closePopup={setEditingDiscipline.bind(null, undefined)}
                    onSave={onAddResult}
                />
            )}
            {editingDiscipline && editingTeam && action === "delete" && (
                <AlertDialog
                    isOpen={!!editingDiscipline}
                    onCancel={setEditingDiscipline.bind(null, undefined)}
                    isCancelable={true}
                >
                    <div className="alert-dialog-title">Spiel löschen!</div>
                    <div className="alert-dialog-content">Möchtest du das Spiel löschen?</div>
                    <div className="d-flex flex-column">
                        <Button onClick={setEditingDiscipline.bind(null, undefined)} className="alert-dialog-button">
                            Abbrechen
                        </Button>
                        <Button onClick={onDeleteResult} className="alert-dialog-button">
                            Löschen
                        </Button>
                    </div>
                </AlertDialog>
            )}
            <Modal isOpen={showBlockedMessage && blocked}>
                <Container className="font-crayon text-1-3">
                    Der Coiffeur wurde bereits beendet. Bist du sicher, dass du ihn editieren möchtest?
                    <br />
                    Striche werden neu berechnet und bereits bezahlte Striche werden nicht beachtet.
                </Container>
                <div className="p-3 fixed-bottom d-flex">
                    <BootstrapButton
                        onClick={setShowBlockedMessage.bind(null, false)}
                        variant="secondary"
                        className="font-chalk-label font-weight bold flex-grow-0"
                    >
                        Abbrechen
                    </BootstrapButton>
                    <BootstrapButton
                        onClick={setBlocked.bind(null, false)}
                        variant="danger"
                        className="font-chalk-label font-weight bold py-4 flex-grow-1 ml-4"
                    >
                        Trotzdem editieren
                    </BootstrapButton>
                </div>
            </Modal>
        </div>
    );
};
