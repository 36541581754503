import classNames from "classnames";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Icon } from "react-onsenui";
import { IUser } from "../../api/domain";
import { IMarks } from "../../api/domain/marks";
import { SchieberService } from "../../api/service/schieber-service";
import { UserImage, UserImageSize } from "../user-image/user-image";
import "./schieber-player.scss";

interface IOwnProps {
    className?: string;
    user: IUser;
    labelPosition: "top" | "bottom";
    groupId: string;
    schieberId: string;
    addMark: (userIds: string[]) => Promise<void>;
    removeMark: (userIds: string[]) => Promise<void>;
}

interface ISchieberPlayerProps extends IOwnProps {}

export const SchieberPlayer: React.FC<ISchieberPlayerProps> = ({
    className,
    addMark,
    removeMark,
    schieberId,
    groupId,
    labelPosition,
    user,
}) => {
    const [marks] = useDocumentData<IMarks>(SchieberService.getSchieberUserMarksRef(groupId, schieberId, user.uid));

    const onAdd = async (e: React.MouseEvent) => {
        e.stopPropagation();
        await addMark([user.uid]);
    };
    const onRemove = async (e: React.MouseEvent) => {
        e.stopPropagation();
        await removeMark([user.uid]);
    };

    return (
        <div onClick={onAdd} className={classNames(className, "schieber-player")}>
            <div key={user.uid} className="d-flex flex-column justify-content-center align-items-center font-crayon">
                {labelPosition === "top" && user.displayName}
                {labelPosition === "bottom" && (
                    <div className="p-2">
                        <div className="px-3" onClick={onRemove}>
                            <Icon className="text-white" icon="md-delete" />
                        </div>
                        {marks?.totalMarks}
                    </div>
                )}
                <UserImage
                    classNameImage="border border-white border-2"
                    size={UserImageSize.SMALL}
                    rounded={true}
                    user={user}
                />
                {labelPosition === "bottom" && user.displayName}
                {labelPosition === "top" && (
                    <div className="p-2">
                        {marks?.totalMarks}
                        <div className="px-3" onClick={onRemove}>
                            <Icon className="text-white" icon="md-delete" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
