import { store } from "react-recollect";
import { firebase, firestore, functions } from "../../firebase";
import { fetchGroups } from "../../store/updaters/fetchGroups";
import { ICreateGroup, IReadGroup, IResolvedGroup } from "../domain/group";
import { UserService } from "./user-service";

export class GroupService {
  static async createGroup(group: ICreateGroup) {
    const createGroup = functions.httpsCallable("createGroup");
    await createGroup(group);
  }

  static async getGroups(): Promise<IResolvedGroup[]> {
    const uid = firebase.auth().currentUser?.uid;
    const response = await firestore
      .collection("groups")
      .where(`members.${uid}`, "==", true)
      .get();
    // @ts-ignore
    const groups: IReadGroup[] = response.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
    }));
    const promises = groups.reduce(
      (acc, { members }) =>
        acc.concat(UserService.getUsers(Object.keys(members))),
      [] as Promise<any>[]
    );
    const users = await Promise.all(promises);
    return groups.map((group, idx) => ({
      ...group,
      members: users[idx],
    }));
  }

  static async getGroup(uid: string): Promise<IResolvedGroup> {
    const cachedGroup = store.groups.find((group) => group.uid === uid);
    if (cachedGroup) {
      return cachedGroup;
    }
    await fetchGroups();
    const group = store.groups.find((group) => group.uid === uid);
    if (!group) {
      throw new Error(`Requested group ${uid} does not exist`);
    }
    return group;
  }
}
