import { afterChange, initStore as initRecollectStore } from "react-recollect";

const users = (sessionStorage.users && JSON.parse(sessionStorage.users)) || {};
const imageUrls = (sessionStorage.imageUrls && JSON.parse(sessionStorage.imageUrls)) || {};
export const initStore = () =>
    initRecollectStore({
        isMenuOpen: false,
        groups: [],
        users,
        disciplines: [],
        imageUrls,
        isLoading: false,
    });

afterChange((e) => {
    if (e.changedProps.includes("users")) {
        sessionStorage.users = JSON.stringify(e.store.users);
    }
    if (e.changedProps.includes("imageUrls")) {
        sessionStorage.imageUrls = JSON.stringify(e.store.imageUrls);
    }
});
