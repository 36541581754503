import { store } from "react-recollect";
import { NotificationService } from "../../api/service/notification-service";
import { SchieberService } from "../../api/service/schieber-service";

export const deleteSchieber = async (schieberId: string) => {
    store.isLoading = true;
    try {
        await SchieberService.deleteSchieber(schieberId);
        NotificationService.success("Schieber wurde gelöscht");
    } catch (e) {
        console.debug("Could not delete Schieber");
        console.debug(e);
        NotificationService.error("Schieber konnte nicht gelöscht werden");
    } finally {
        store.isLoading = false;
    }
};
