import React from "react";
import "./log-list-item.scss";
import { ListItem } from "react-onsenui";
import { LogEventType, StoredLogEvent } from "../../../api/domain";
import { store } from "react-recollect";

interface IOwnProps {
    className?: string;
    logEvent: StoredLogEvent;
}

interface ILogListItemProps extends IOwnProps {}

const getLogContent = (logEvent: StoredLogEvent) => {
    switch (logEvent.type) {
        case LogEventType.COIFFEUR_STARTED:
            return `Coiffeur gestartet`;
        case LogEventType.RESULT_ADDED:
            return (
                <div>
                    <i>
                        {`${store.disciplines.find((d) => d.uid === logEvent.disciplineId)?.name} ${
                            logEvent.match ? "Match" : ""
                        } gespielt (${logEvent.points})`}{" "}
                    </i>{" "}
                    <br />
                    angesagt von {store.users[logEvent.player.uid]?.displayName}
                </div>
            );
        case LogEventType.RESULT_DELETED:
            return `Resultat gelöscht (${store.disciplines.find((d) => d.uid === logEvent.disciplineId)?.name})`;
        default:
            return "";
    }
};

export const LogListItem: React.FC<ILogListItemProps> = ({ logEvent }) => {
    return (
        <ListItem>
            <span className="list-item__subtitle">{getLogContent(logEvent)}</span>
            <span className="list-item__subtitle">
                <b>{logEvent.date.toDate().toLocaleTimeString()}</b>
            </span>
        </ListItem>
    );
};
