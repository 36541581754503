import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router";
import { IUser } from "../../../../api/domain";
import { ITeam } from "../../../../api/domain/team";
import { UserImage, UserImageSize } from "../../../user-image/user-image";
import "./slate-team-block.scss";

interface IOwnProps {
    className?: string;
    team: ITeam;
}

interface ISlateTeamBlockProps extends IOwnProps {}

export const SlateTeamBlock: React.FC<ISlateTeamBlockProps> = ({ className, team }) => {
    const users: IUser[] = Object.keys(team.members).map((memberId) => team.members[memberId]);
    const history = useHistory();

    return (
        <div
            className={classNames(
                className,
                "slate-team-block d-flex flex-column text-white font-crayon justify-content-center align-items-center"
            )}
        >
            <div className="d-flex justify-content-center align-items-center">
                {users.map((user) => (
                    <div
                        key={user.uid}
                        className="slate-team-block__member d-flex flex-column justify-content-center align-items-center"
                    >
                        <UserImage
                            openProfileOnClick={true}
                            history={history}
                            classNameImage="slate-team-block__member-image"
                            user={user}
                            size={UserImageSize.EXTRA_SMALL}
                            rounded={true}
                            className="text-white"
                        />
                    </div>
                ))}
            </div>
            {users && users.length === 2 && (
                <div className="d-flex justify-content-center">
                    <div className="text-center slate-team-block__member-name pl-1">{users[0].displayName}</div>
                    <div className="text-truncate text-center px-1">&</div>
                    <div className="text-center slate-team-block__member-name pr-1">{users[1].displayName}</div>
                </div>
            )}
        </div>
    );
};
