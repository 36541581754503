import React from "react";
import {
  BackButton,
  Icon,
  Toolbar as OnsenToolbar,
  ToolbarButton,
  Button,
} from "react-onsenui";
import "./toolbar.scss";

interface IOwnProps {
  className?: string;
  title?: string;
  onBack?: () => void;
  onNext?: () => void;
  onMenuToggle?: () => void;
}

interface IToolbarProps extends IOwnProps {}

export const Toolbar: React.FC<IToolbarProps> = ({
  title,
  onBack,
  onNext,
  onMenuToggle = () => undefined,
}) => {
  return (
    <OnsenToolbar>
      {onBack && (
        <div className="left">
          <BackButton onClick={onBack}>Zurück</BackButton>
        </div>
      )}
      {!onBack && (
        <div className="left">
          <ToolbarButton onClick={onMenuToggle}>
            <Icon icon="md-menu" />
          </ToolbarButton>
        </div>
      )}
      <div className="center">{title}</div>
      {onNext && (
        <div className="right pr-2 d-flex align-items-center">
          <Button className="flex-grow-0" onClick={onNext}>
            Weiter
          </Button>
        </div>
      )}
    </OnsenToolbar>
  );
};
