import classNames from "classnames";
import React from "react";
import { ICoiffeur } from "../../../api/domain/coiffeur";
import { IDisciplineConfiguration } from "../../../api/domain/coiffeur-configuration";
import { SlateDiscipline } from "../slate-discipline/slate-discipline";
import "./slate-category-column.scss";
import { SlateCategoryMetadataBlock } from "./slate-category-metadata-block/slate-category-metadata-block";

interface IOwnProps {
    className?: string;
    coiffeur: ICoiffeur;
    disciplines: IDisciplineConfiguration[];
}

interface ISlateCategoryColumnProps extends IOwnProps {}

export const SlateCategoryColumn: React.FC<ISlateCategoryColumnProps> = ({ className, coiffeur, disciplines }) => {
    return (
        <div className={classNames(className, "slate-category-column position-relative")}>
            {disciplines &&
                disciplines.map((discipline, idx) => (
                    <SlateDiscipline
                        discipline={discipline}
                        key={idx}
                        className="border-bottom border-white border-2"
                    />
                ))}
            <div className="slate-category-column__metadata-wrapper d-flex flex-column font-chalk-label text-white bg-slate position-fixed">
                <SlateCategoryMetadataBlock
                    coiffeur={coiffeur}
                    className="border-bottom border-right border-white border-4 w-100"
                />
            </div>
            <div className="slate-category-column__total-wrapper border-top border-right border-white border-4 text-center font-chalk-label font-weight-bold text-white bg-slate position-fixed d-flex justify-content-center align-items-center">
                Total
            </div>
        </div>
    );
};
