import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IUser } from "../api/domain";
import { ICoiffeur } from "../api/domain/coiffeur";
import { ICoiffeurConfiguration } from "../api/domain/coiffeur-configuration";
import { IResolvedGroup } from "../api/domain/group";
import { IResult } from "../api/domain/result";
import { CoiffeurService } from "../api/service/coiffeur-service";
import { addCoiffeurResult } from "../store/updaters/addCoiffeurResult";
import { createCoiffeur } from "../store/updaters/createCoiffeur";
import { deleteCoiffeurResult } from "../store/updaters/deleteCoiffeurResult";

const isTeamComplete = (team?: IUser[]) => team && team.length === 2;
const teamsComplete = (teams?: IUser[][]) => teams && teams.length === 2 && teams.every(isTeamComplete);
export const useCoiffeur = (
    coiffeurId?: string
): [
    ICoiffeur | undefined,
    boolean,
    any,
    (group: IResolvedGroup, configuration: ICoiffeurConfiguration, teams: IUser[][]) => Promise<void>,
    (
        teamId: string,
        opponentTeamId: string,
        { player, match, disciplineId, counterMatch, points }: IResult
    ) => Promise<void>,
    (teamId: string, opponentTeamId: string, disciplineId: string) => Promise<void>
] => {
    const [coiffeur, setCoiffeur] = useState<ICoiffeur>();
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!coiffeurId) {
            return;
        }
        setLoading(true);
        CoiffeurService.getCoiffeur(coiffeurId)
            .then((coiffeur) => setCoiffeur(coiffeur))
            .catch((e) => setError(e))
            .finally(setLoading.bind(null, false));
    }, [coiffeurId]);

    const startCoiffeur = async (group: IResolvedGroup, configuration: ICoiffeurConfiguration, teams: IUser[][]) => {
        setLoading(true);
        try {
            if (!group || !configuration || !teamsComplete(teams)) {
                throw new Error("Invalid arguments");
            }
            const coiffeurId = await createCoiffeur(group, teams, configuration);
            if (!coiffeurId) {
                throw new Error("No coiffeur id returned");
            }
            setLoading(false);
            history.replace(`/coiffeur/${coiffeurId}`);
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    };

    const deleteResult = async (teamId: string, opponentTeamId: string, disciplineId: string) => {
        if (!coiffeur) {
            throw new Error("Cant delete result for unknown coiffeur");
        }
        setLoading(true);
        try {
            await deleteCoiffeurResult({
                groupId: coiffeur.groupId,
                coiffeurId: coiffeur.uid,
                teamId,
                opponentTeamId,
                disciplineId,
            });
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    const addResult = async (
        teamId: string,
        opponentTeamId: string,
        { player, match, disciplineId, multiplier, counterMatch, points }: IResult
    ) => {
        if (!coiffeur) {
            throw new Error("Cant create result for unknown coiffeur");
        }
        setLoading(true);
        try {
            await addCoiffeurResult(coiffeur.uid, coiffeur.groupId, teamId, opponentTeamId, {
                points,
                disciplineId,
                player,
                multiplier,
                match,
                counterMatch,
            });
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    return [coiffeur, loading, error, startCoiffeur, addResult, deleteResult];
};
