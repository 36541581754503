import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { ICoiffeur } from "../../../api/domain/coiffeur";
import { IDisciplineConfiguration } from "../../../api/domain/coiffeur-configuration";
import { IResult } from "../../../api/domain/result";
import { ITeam } from "../../../api/domain/team";
import { CoiffeurService } from "../../../api/service/coiffeur-service";
import { SlateResultBlock } from "../slate-result-block/slate-result-block";
import "./slate-result-column.scss";
import { SlateTeamBlock } from "./slate-team-block/slate-team-block";

export enum TeamPosition {
    LEFT,
    RIGHT,
}

interface IOwnProps {
    className?: string;
    position: TeamPosition;
    team: ITeam;
    disciplines: IDisciplineConfiguration[];
    coiffeur: ICoiffeur;
    disciplineAction: (team: ITeam, discipline: IDisciplineConfiguration, resultExists: boolean) => void;
}

interface ISlateResultColumnProps extends IOwnProps {}

export const SlateResultColumn: React.FC<ISlateResultColumnProps> = ({
    className,
    disciplines,
    team,
    position,
    coiffeur,
    disciplineAction,
}) => {
    const [results] = useCollectionData<IResult>(
        CoiffeurService.getCoiffeurResultsRef(coiffeur.uid, coiffeur.groupId, team.uid)
    );
    const resultMap: { [key: string]: IResult } | undefined = useMemo(
        () =>
            results?.reduce(
                (acc, val) => ({
                    ...acc,
                    [val.disciplineId]: val,
                }),
                {}
            ),
        [results]
    );
    const [oldScore, setOldScore] = useState([team.score, team.score]);
    useEffect(() => {
        setOldScore([oldScore[1], team.score]);
        // eslint-disable-next-line
    }, [team.score]);
    return (
        <div className={classNames(className, "slate-result-column")}>
            {disciplines.map((discipline) => (
                <SlateResultBlock
                    position={position}
                    result={resultMap?.[discipline.disciplineId]}
                    onClick={disciplineAction.bind(null, team, discipline, !!resultMap?.[discipline.disciplineId])}
                    key={discipline.disciplineId}
                    className={classNames("border-bottom border-white border-2")}
                />
            ))}
            <SlateTeamBlock
                team={team}
                className="slate-result-column__team-wrapper bg-slate position-fixed bg-slate"
            />
            <div className="slate-result-column__total-wrapper text-center font-crayon text-white bg-slate position-fixed">
                <CountUp start={oldScore[0]} end={team.score || 0} duration={3} delay={1} />
            </div>
        </div>
    );
};
