import { ITeam } from "../../api/domain/team";
import { NotificationService } from "../../api/service/notification-service";
import { SchieberService } from "../../api/service/schieber-service";

export const setSchieberWinner = async ({
    schieberId,
    groupId,
    team,
}: {
    groupId: string;
    schieberId: string;
    team?: ITeam;
}) => {
    try {
        await SchieberService.setSchieberWinner({ groupId, schieberId, team });
        if (team) {
            NotificationService.successTop("Spiel beendet");
        } else {
            NotificationService.infoTop("Spiel wurde wieder geöffnet");
        }
    } catch (e) {
        NotificationService.errorTop("Spiel konnte nicht beendet werden");
    }
};
