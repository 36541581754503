import classNames from "classnames";
import React, { useMemo } from "react";
import { Bar, CartesianGrid, ComposedChart, Label, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { IDisciplineSingleStatistic } from "../../../api/domain";
import "./players-and-teams-single-discipline-chart.scss";

interface IOwnProps {
    className?: string;
    statistics: IDisciplineSingleStatistic[];
}

const getLabelOfDisciplineSingleStatistic = (disciplineSingleStatistic: IDisciplineSingleStatistic) => {
    if (disciplineSingleStatistic.displayName) {
        return disciplineSingleStatistic.displayName;
    }
    if (!disciplineSingleStatistic.members) {
        return "";
    }
    const memberIds = Object.keys(disciplineSingleStatistic.members).sort();
    return `${disciplineSingleStatistic.members[memberIds[0]].displayName} & ${
        disciplineSingleStatistic.members[memberIds[1]].displayName
    }`;
};

interface IPlayersAndTeamsSingleDisciplineChartProps extends IOwnProps {}

interface IDisciplineSingleStatisticWithLabel extends IDisciplineSingleStatistic {
    label: string;
    winQuota: number;
    tieQuota: number;
    lossQuota: number;
    winQuotaLabel: string;
}

export const PlayersAndTeamsSingleDisciplineChart: React.FC<IPlayersAndTeamsSingleDisciplineChartProps> = ({
    className,
    statistics,
}) => {
    const data: IDisciplineSingleStatisticWithLabel[] = useMemo(
        () =>
            statistics.map((statistic) => {
                const tieQuota = Math.round(((statistic.numberOfTimesTie || 0) * 100) / statistic.numberOfTimesPlayed);
                return {
                    ...statistic,
                    label: getLabelOfDisciplineSingleStatistic(statistic),
                    teamName: getLabelOfDisciplineSingleStatistic(statistic),
                    winQuota: Math.round((statistic.numberOfTimesWon * 100) / statistic.numberOfTimesPlayed),
                    lossQuota: Math.round((statistic.numberOfTimesLost * 100) / statistic.numberOfTimesPlayed),
                    tieQuota,
                    tieQuotaLabel: tieQuota ? `${tieQuota}%` : "",
                    tieQuotaRight: !statistic.isAverageStatisticEntry
                        ? `${statistic.numberOfTimesTie || 0} von ${statistic.numberOfTimesPlayed || 0}`
                        : "",
                    winQuotaLabel: statistic.numberOfTimesWon
                        ? `${Math.round((statistic.numberOfTimesWon * 100) / statistic.numberOfTimesPlayed)}% 🏆`
                        : "",
                    winQuotaRight: !statistic.isAverageStatisticEntry
                        ? `${statistic.numberOfTimesWon || 0} von ${statistic.numberOfTimesPlayed || 0}`
                        : "",
                    lossQuotaLabel: statistic.numberOfTimesLost
                        ? `${Math.round((statistic.numberOfTimesLost * 100) / statistic.numberOfTimesPlayed)}% ✘`
                        : "",
                    lossQuotaRight: statistic.isAverageStatisticEntry
                        ? ""
                        : `${statistic.numberOfTimesLost || 0} von ${statistic.numberOfTimesPlayed || 0}`,
                    averagePointsLabel: ` Ø ${statistic.averagePoints}`,
                    teamNameDataPoint: statistic.isAverageStatisticEntry ? 0 : 100,
                };
            }),
        [statistics]
    );

    return (
        <div className={classNames(className, "players-and-teams-single-discipline-chart")}>
            <ResponsiveContainer width="100%" height={750}>
                <ComposedChart
                    layout="vertical"
                    data={data}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    barGap={3}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis
                        xAxisId="points"
                        type="number"
                        domain={[0, 16]}
                        tickLine={false}
                        axisLine={false}
                        interval={0}
                        minTickGap={0}
                        orientation="top"
                    >
                        <Label fill="rgba(0,0,0,.5)" value="Punkte" offset={0} position="insideTop" />
                    </XAxis>
                    <XAxis
                        xAxisId="percentage"
                        type="number"
                        domain={[0, 100]}
                        tickLine={false}
                        axisLine={false}
                        interval={0}
                        minTickGap={0}
                        unit=" %"
                    >
                        <Label fill="rgba(0,0,0,.3)" value="Gewinnquote" offset={0} position="insideBottom" />
                    </XAxis>
                    <YAxis interval={0} type="category" dataKey="label" tickLine={false} axisLine={false} hide={true} />
                    <Bar
                        radius={2}
                        xAxisId="percentage"
                        barSize={20}
                        isAnimationActive={false}
                        dataKey="teamNameDataPoint"
                        fill="white"
                    >
                        <LabelList
                            formatter={(val: any) => val.toLocaleString().replace(/\s/g, "\u00A0")}
                            className="bar-label bar-label-bold"
                            dataKey="teamName"
                            position="insideBottomLeft"
                        />
                    </Bar>
                    <Bar
                        radius={2}
                        xAxisId="percentage"
                        barSize={15}
                        isAnimationActive={false}
                        dataKey="winQuota"
                        fill="rgb(87, 167, 115)"
                    >
                        <LabelList className="bar-label bar-label-white" dataKey="winQuotaLabel" position="inside" />
                        <LabelList className="bar-label bar-label-gray" dataKey="winQuotaRight" position="right" />
                    </Bar>
                    <Bar
                        radius={2}
                        xAxisId="percentage"
                        barSize={15}
                        isAnimationActive={false}
                        dataKey="lossQuota"
                        fill="rgb(238, 99, 82)"
                    >
                        <LabelList className="bar-label bar-label-white" dataKey="lossQuotaLabel" position="inside" />
                        <LabelList className="bar-label bar-label-gray" dataKey="lossQuotaRight" position="right" />
                    </Bar>
                    <Bar
                        radius={2}
                        xAxisId="percentage"
                        barSize={15}
                        isAnimationActive={false}
                        dataKey="tieQuota"
                        fill="rgba(0,0,0,0.1)"
                    >
                        <LabelList className="bar-label bar-label-gray" dataKey="tieQuotaRight" position="right" />
                    </Bar>
                    <Bar
                        radius={[2, 5, 5, 2]}
                        xAxisId="points"
                        barSize={7}
                        isAnimationActive={false}
                        dataKey="averagePoints"
                        fill="#8d8d8d"
                    >
                        <LabelList className="bar-label" dataKey="averagePointsLabel" position="right" />
                    </Bar>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    );
};
