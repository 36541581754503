import { GroupService } from "../../api/service/group-service";
import { NotificationService } from "../../api/service/notification-service";
import { fetchGroups } from "./fetchGroups";

export const createGroup = async (name: string, members: string[]) => {
  try {
    await GroupService.createGroup({
      name,
      members,
    });
    await fetchGroups();
    NotificationService.success("Gruppe wurde erstellt");
  } catch (e) {
    console.debug("Could not create group");
    console.debug(e);
    NotificationService.error("Gruppe konnte nicht erstellt werden");
  }
};
