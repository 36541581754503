import { MarksService } from "../../api/service/marks-service";
import { NotificationService } from "../../api/service/notification-service";

export const addSchieberMark = async (groupId: string, schieberId: string, userIds: string[]) => {
    try {
        await MarksService.addSchieberMark(groupId, schieberId, userIds);
        NotificationService.successTop("Strich geschrieben");
    } catch (e) {
        NotificationService.errorTop("Strich konnte nicht geschrieben werden");
    }
};
