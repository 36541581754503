import React from "react";
import { ListItem } from "react-onsenui";
import { useHistory } from "react-router";
import { IUser } from "../../../api/domain";
import { UserImage, UserImageSize } from "../../user-image/user-image";
import "./user-list-item.scss";

interface IOwnProps {
    user: IUser;
    onClick?: (user: IUser) => void;
}

interface IUserListItemProps extends IOwnProps {}

export const UserListItem: React.FC<IUserListItemProps> = ({ user, onClick = () => undefined }) => {
    const history = useHistory();
    return (
        <ListItem onClick={() => onClick(user)} className="d-flex align-items-center">
            <UserImage
                history={history}
                openProfileOnClick={true}
                rounded={true}
                user={user}
                size={UserImageSize.EXTRA_SMALL}
            />
            <span className="pl-3">{user.displayName}</span>
        </ListItem>
    );
};
