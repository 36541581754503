import React, { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Icon, List, ListHeader, ProgressBar } from "react-onsenui";
import { IGroupSchieberSettings } from "../../../api/domain/group-settings";
import { firestore } from "../../../firebase";
import SchieberTafel from "../../../img/slate_schieber.png";
import { updateSchieberSettings } from "../../../store/updaters/updateSettings";
import { InputListItem } from "../../list-items/input-list-item/input-list-item";
import "./schieber-settings.scss";

interface IOwnProps {
    className?: string;
    groupId: string;
}

interface ISchieberSettingsProps extends IOwnProps {}

const getSettingsDocumentReference = (groupId: string) =>
    firestore.collection("groups").doc(`${groupId}`).collection("settings").doc("schieber");

export const SchieberSettings: React.FC<ISchieberSettingsProps> = ({ groupId }) => {
    const doc = getSettingsDocumentReference(groupId);
    const [settings, loading] = useDocumentData<IGroupSchieberSettings>(doc);
    const [updating, setUpdating] = useState(false);
    const updateSettings = async (updatedSettings: Partial<IGroupSchieberSettings>) => {
        setUpdating(true);
        await updateSchieberSettings(updatedSettings, groupId);
        setUpdating(false);
    };
    const saveCostsPerMark = (costsPerMark: number) => updateSettings({ costsPerMark });
    // const saveMountain = (mountain: number) => updateSettings({ mountain });
    // const saveMountainNotReached = (mountainNotReached: number) =>
    //   updateSettings({ mountainNotReached });
    // const saveMatch = (match: number) => updateSettings({ match });
    // const saveCounterMatch = (counterMatch: number) =>
    //   updateSettings({ counterMatch });
    // const saveVictory = (victory: number) => updateSettings({ victory });

    return loading ? (
        <ProgressBar indeterminate={true} />
    ) : (
        <List>
            <ListHeader>
                <img alt="Schieber Jasstafel" className="mr-3" width={30} src={SchieberTafel} />
                Schieber
            </ListHeader>
            <InputListItem
                disabled={updating}
                value={settings?.costsPerMark || 0}
                label="Kosten pro Strich"
                type="number"
                icon={<Icon icon="fa-coins" />}
                onSave={saveCostsPerMark}
                decimal={true}
                unit="CHF"
            />
            {/*<InputListItem*/}
            {/*  disabled={updating}*/}
            {/*  value={settings?.mountain || 0}*/}
            {/*  onSave={saveMountain}*/}
            {/*  label="Bergpreis"*/}
            {/*  type="number"*/}
            {/*  icon={<Icon icon="fa-mountain" />}*/}
            {/*  unit="Striche"*/}
            {/*/>*/}
            {/*<InputListItem*/}
            {/*  disabled={updating}*/}
            {/*  value={settings?.mountainNotReached || 0}*/}
            {/*  onSave={saveMountainNotReached}*/}
            {/*  label="Niederlage ohne Berg"*/}
            {/*  type="number"*/}
            {/*  icon={*/}
            {/*    <Icon style={{ transform: "rotate(180deg)" }} icon="fa-mountain" />*/}
            {/*  }*/}
            {/*  unit="Striche"*/}
            {/*/>*/}
            {/*<InputListItem*/}
            {/*  disabled={updating}*/}
            {/*  value={settings?.match || 0}*/}
            {/*  onSave={saveMatch}*/}
            {/*  label="Match"*/}
            {/*  type="number"*/}
            {/*  icon={<Icon icon="fa-thumbs-up" />}*/}
            {/*  unit="Striche"*/}
            {/*/>*/}
            {/*<InputListItem*/}
            {/*  disabled={updating}*/}
            {/*  value={settings?.counterMatch || 0}*/}
            {/*  onSave={saveCounterMatch}*/}
            {/*  label="Kontermatch"*/}
            {/*  type="number"*/}
            {/*  icon={<Icon icon="fa-thumbs-down" />}*/}
            {/*  unit="Striche"*/}
            {/*/>*/}
            {/*<InputListItem*/}
            {/*  disabled={updating}*/}
            {/*  value={settings?.victory || 0}*/}
            {/*  onSave={saveVictory}*/}
            {/*  label="Sieg"*/}
            {/*  type="number"*/}
            {/*  icon={<Icon icon="fa-trophy" />}*/}
            {/*  unit="Striche"*/}
            {/*/>*/}
        </List>
    );
};
