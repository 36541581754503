import "onsenui/css/onsen-css-components.css";
import "onsenui/css/onsenui.css";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { firebase } from "./firebase";
import * as serviceWorker from "./serviceWorker";
import "./store/initStore";
import { initStore } from "./store/initStore";
import { fetchDisciplines } from "./store/updaters/fetchDisciplines";
import { fetchGroups } from "./store/updaters/fetchGroups";
import "./style/style.scss";

initStore();

// async function askUserPermission() {
//     return await Notification.requestPermission();
// }

firebase.auth().onAuthStateChanged((user) => {
    if (!user) {
        return;
    }
    fetchGroups();
    fetchDisciplines();
});
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
