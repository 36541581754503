import classNames from "classnames";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Card, Icon } from "react-onsenui";
import { useHistory } from "react-router";
import { IUser } from "../../../api/domain";
import { ICoiffeurWithTeams } from "../../../api/domain/coiffeur";
import { formatTimestampDate } from "../../../util/date-helpers";
import { UserImage, UserImageSize } from "../../user-image/user-image";
import "./coiffeur-list-item.scss";

interface IOwnProps {
    className?: string;
    coiffeur: ICoiffeurWithTeams;
    deleteCoiffeur: (coiffeurId: string) => void;
}

interface ICoiffeurListItemProps extends IOwnProps {}

const renderTeam = (team: IUser[], score: number, totalScore: number, winner: boolean) => {
    const percentage = (score * 100) / totalScore;
    const contrary = 100 - percentage;
    const color = winner
        ? `rgba(89,255,0, ${Math.max(0.4, percentage / 100)})`
        : `rgba(255,0,0, ${Math.max(0.4, contrary / 100)})`;

    return (
        <Row className="p-2">
            <Col>
                {team.map((user) => (
                    <div key={user.uid} className="d-flex align-items-center">
                        <UserImage
                            innerStyle={{ border: `1px solid ${color}`, color }}
                            classNameImage="border-1"
                            size={UserImageSize.EXTRA_SMALL}
                            user={user}
                            rounded={true}
                        />
                        <span className="pl-3 font-crayon text-white">{user.displayName}</span>
                    </div>
                ))}
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
                <div className="w-50 d-flex justify-content-end align-items-center">
                    <CircularProgressbar
                        styles={buildStyles({
                            strokeLinecap: "butt",
                            textSize: "28px",
                            pathColor: color,
                            textColor: color,
                            trailColor: "rgba(255,255,255,0.2)",
                        })}
                        strokeWidth={4}
                        text={`${score}`}
                        value={score}
                        minValue={0}
                        maxValue={totalScore}
                        counterClockwise={winner}
                    />
                </div>
            </Col>
        </Row>
    );
};

export const CoiffeurListItem: React.FC<ICoiffeurListItemProps> = ({ className, coiffeur, deleteCoiffeur }) => {
    const teams: IUser[][] = useMemo(
        () => coiffeur.teams.map((team) => Object.keys(team.members).map((userId) => team.members[userId] as IUser)),
        [coiffeur]
    );
    const winners = coiffeur?.winners || {};
    const scoreTeam1 = coiffeur.teams[0].score || 0;
    const scoreTeam2 = coiffeur.teams[1].score || 1;
    const totalScore = scoreTeam1 + scoreTeam2;
    const history = useHistory();
    const onDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        deleteCoiffeur(coiffeur.uid);
    };

    return (
        <Card className={classNames(className, "coiffeur-list-item border-bottom bg-slate text-white")}>
            <div onClick={() => history.push(`/coiffeur/${coiffeur.uid}`)}>
                <div className="d-flex justify-content-between align-items-center font-weight-bold font-crayon">
                    {formatTimestampDate(coiffeur.date)}
                    <div className="p-1 px-2" onClick={onDelete}>
                        <Icon className="text-white text-1-4" icon="md-delete" />
                    </div>
                </div>
                {renderTeam(teams[0], scoreTeam1, totalScore, !!winners[teams[0][0].uid])}
                {renderTeam(teams[1], scoreTeam2, totalScore, !!winners[teams[1][0].uid])}
            </div>
        </Card>
    );
};
