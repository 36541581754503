import { IUser } from "../../api/domain";
import { IResolvedGroup } from "../../api/domain/group";
import { NotificationService } from "../../api/service/notification-service";
import { SchieberService } from "../../api/service/schieber-service";

export const createSchieber = async (group: IResolvedGroup, teams: IUser[][]) => {
    try {
        const coiffeurId = await SchieberService.createSchieber(group, teams);
        NotificationService.success("Schieber wurde gestartet");
        return coiffeurId;
    } catch (e) {
        console.debug("Could not create Schieber");
        console.debug(e);
        NotificationService.error("Schieber konnte nicht gestartet werden");
    }
    return null;
};
