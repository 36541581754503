import React from "react";
import { Modal } from "react-onsenui";
import { collect, useProps } from "react-recollect";
import { WithStoreProp } from "react-recollect/dist/types/shared/types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ProgressIndicator } from "./components/progress-indicator/progress-indicator";
import { Router } from "./components/router/router";
import "./firebase";

interface IOwnProps extends WithStoreProp {}

const InternalApp: React.FC<IOwnProps> = ({ store }) => {
    useProps([store.isLoading]);

    return (
        <>
            <Router />
            {/*// @ts-ignore*/}
            <ToastContainer
                position="bottom-center"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                draggable={true}
                pauseOnHover={true}
            />
            {/*// @ts-ignore*/}
            <Modal isOpen={store.isLoading}>
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <ProgressIndicator />
                </div>
            </Modal>
        </>
    );
};

export const App = collect(InternalApp);
