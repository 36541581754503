import classNames from "classnames";
import React, { useMemo } from "react";
import { useCollectionDataOnce, useDocumentData } from "react-firebase-hooks/firestore";
import { Card } from "react-onsenui";
import { IDisciplineSingleStatistic, IDisciplineStatistic } from "../../../api/domain";
import { IDiscipline } from "../../../api/domain/discipline";
import { firestore } from "../../../firebase";
import { DisciplineImage, DisciplineImageSize } from "../../discipline-image/discipline-image";
import { PlayersAndTeamsSingleDisciplineChart } from "../players-and-teams-single-discipline-chart/players-and-teams-single-discipline-chart";
import "./discipline-average-statistic.scss";

interface IOwnProps {
    className?: string;
    discipline: IDiscipline;
    groupId: string;
}

interface IDisciplineAverageStatisticProps extends IOwnProps {}

const getStatisticsDisciplineReference = (groupId: string, disciplineId: string) =>
    firestore.collection("statistics").doc(groupId).collection("disciplines").doc(disciplineId);
const getStatisticsDisciplinePlayersReference = (groupId: string, disciplineId: string) =>
    firestore.collection("statistics").doc(groupId).collection("disciplines").doc(disciplineId).collection("players");
const getStatisticsDisciplineTeamsReference = (groupId: string, disciplineId: string) =>
    firestore.collection("statistics").doc(groupId).collection("disciplines").doc(disciplineId).collection("teams");

export const DisciplineAverageStatistic: React.FC<IDisciplineAverageStatisticProps> = ({
    className,
    discipline,
    groupId,
}) => {
    const doc = getStatisticsDisciplineReference(groupId, discipline.uid);
    const [disciplineStatistic] = useDocumentData<IDisciplineStatistic>(doc);
    const [players] = useCollectionDataOnce<IDisciplineSingleStatistic>(
        getStatisticsDisciplinePlayersReference(groupId, discipline.uid)
    );
    const [teams] = useCollectionDataOnce<IDisciplineSingleStatistic>(
        getStatisticsDisciplineTeamsReference(groupId, discipline.uid)
    );
    const playersAndTeams = useMemo(() => {
        if (!players || !teams) {
            return [];
        }
        const numberOfTimesPlayed = teams.reduce((acc, val) => acc + val.numberOfTimesPlayed, 0);
        return teams
            .concat([
                {
                    displayName: "Durchschnitt Ø",
                    numberOfTimesPlayed: numberOfTimesPlayed,
                    numberOfTimesWon: numberOfTimesPlayed,
                    numberOfTimesTie: numberOfTimesPlayed,
                    numberOfTimesLost: numberOfTimesPlayed,
                    averagePoints: (disciplineStatistic && disciplineStatistic.averagePoints) || 0,
                    numberOfTimesMatch: 0,
                    totalPoints: 0,
                    isAverageStatisticEntry: true,
                },
            ])
            .sort((d1, d2) =>
                d1.isAverageStatisticEntry
                    ? +1
                    : Math.round((d2.numberOfTimesWon * 100) / d2.numberOfTimesPlayed) -
                      Math.round((d1.numberOfTimesWon * 100) / d1.numberOfTimesPlayed)
            );
    }, [players, teams, disciplineStatistic]);

    return (
        <Card className={classNames(className, "discipline-average-statistic")}>
            {disciplineStatistic && (
                <div className="d-flex align-items-center">
                    <DisciplineImage
                        inverted={true}
                        disciplineId={discipline.uid}
                        size={DisciplineImageSize.EXTRA_SMALL}
                    />
                    <b className="pl-2">{discipline.name}</b>
                </div>
            )}
            <div className="discipline-average-statistic-body pt-2">
                <PlayersAndTeamsSingleDisciplineChart statistics={playersAndTeams} />
            </div>
        </Card>
    );
};
