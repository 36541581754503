import { store } from "react-recollect";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { NotificationService } from "../../api/service/notification-service";

export const deleteCoiffeur = async (coiffeurId: string) => {
    store.isLoading = true;
    try {
        await CoiffeurService.deleteCoiffeur(coiffeurId);
        NotificationService.success("Coiffeur wurde gelöscht");
        return coiffeurId;
    } catch (e) {
        console.debug("Could not delete Coiffeur");
        console.debug(e);
        NotificationService.error("Coiffeur konnte nicht gelöscht werden");
    } finally {
        store.isLoading = false;
    }
};
