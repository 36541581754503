import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Card } from "react-onsenui";
import { useHistory } from "react-router";
import { IUser } from "../../api/domain";
import { ICoiffeurConfiguration } from "../../api/domain/coiffeur-configuration";
import { IResolvedGroup } from "../../api/domain/group";
import { CoiffeurConfigurator } from "../../components/coiffeur-configurator/coiffeur-configurator";
import { Page } from "../../components/page/page";
import { TeamConfigurator } from "../../components/team-configurator/team-configurator";
import { useCoiffeur } from "../../hooks/use-coiffeur";
import "./create-coiffeur-page.scss";

interface IOwnProps {}

interface ICreateCoiffeurPageProps extends IOwnProps {}

enum CreateCoiffeurStep {
    TEAM = 1,
    DISCIPLINES = 2,
}

const stepTitle = {
    [CreateCoiffeurStep.TEAM.valueOf()]: "Teams wählen",
    [CreateCoiffeurStep.DISCIPLINES.valueOf()]: "Konfigurieren",
};

const isTeamComplete = (team?: IUser[]) => team && team.length === 2;
const teamsComplete = (teams?: IUser[][]) => teams && teams.length === 2 && teams.every(isTeamComplete);

export const CreateCoiffeurPage: React.FC<ICreateCoiffeurPageProps> = () => {
    const history = useHistory();

    const [, creatingCoiffeur, , startCoiffeur] = useCoiffeur();
    const [step, setStep] = useState(1);
    const [group, setGroup] = useState<IResolvedGroup>();
    const [teams, setTeams] = useState<IUser[][]>([]);
    const [configuration, setConfiguration] = useState<ICoiffeurConfiguration>();
    const onBack = () => (step > 1 ? setStep(step - 1) : history.push("/"));
    const onNext = step < 2 && teamsComplete(teams) ? () => setStep(step + 1) : undefined;

    const onTeamSave = (group: IResolvedGroup, teams: IUser[][]) => {
        setGroup(group);
        setTeams(teams);
    };

    useEffect(() => {
        if (!group || !configuration || !teamsComplete(teams)) {
            return;
        }
        startCoiffeur(group, configuration, teams);
        // eslint-disable-next-line
    }, [configuration]);

    return (
        <Page
            swipeable={false}
            onBack={onBack}
            onNext={onNext}
            title={creatingCoiffeur ? "Starten..." : stepTitle[step]}
        >
            <div>
                {!creatingCoiffeur && step === CreateCoiffeurStep.TEAM && (
                    <TeamConfigurator teams={teams} onSave={onTeamSave} />
                )}
                {!creatingCoiffeur && step === CreateCoiffeurStep.DISCIPLINES && (
                    <CoiffeurConfigurator saveLabel="Übernehmen und starten" onSave={setConfiguration} />
                )}
                {creatingCoiffeur && (
                    <Card>
                        <Skeleton count={10} />
                    </Card>
                )}
            </div>
        </Page>
    );
};
