import classNames from "classnames";
import firebase from "firebase";
import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton/lib";
import { Button } from "react-onsenui";
import { useHistory } from "react-router";
import { IMarks } from "../../api/domain/marks";
import { MarksService } from "../../api/service/marks-service";
import { Page } from "../../components/page/page";
import { formatTimestampDate, formatTimestampTime } from "../../util/date-helpers";
import { formatCHFAmount } from "../../util/number-helpers";
import "./marks-page.scss";

interface IOwnProps {
    className?: string;
}

interface IMarksPageProps extends IOwnProps {}

export const MarksPage: React.FC<IMarksPageProps> = ({ className }) => {
    const [loading, setLoading] = useState(false);
    const [marks, setMarks] = useState<(IMarks & { ref: firebase.firestore.DocumentReference<any> })[]>();
    const [payedMarks, setPayedMarks] = useState<(IMarks & { ref: firebase.firestore.DocumentReference<any> })[]>();
    const fetchMarks = async () => {
        setLoading(true);
        setPayedMarks([]);
        const unpaidMarksDocs = await MarksService.getUnpaidMarksQuery().get();
        const updatedMarks = unpaidMarksDocs.docs.map((doc) => ({
            ...(doc.data() as IMarks),
            ref: doc.ref,
        }));
        setMarks(updatedMarks);
        setLoading(false);
    };
    const fetchPayedMarks = async () => {
        setLoading(true);
        const paidMarksDocs =
            payedMarks && payedMarks.length
                ? await MarksService.getPaidMarksQuery()
                      .limit(2)
                      .startAfter(payedMarks[payedMarks.length - 1].date)
                      .get()
                : await MarksService.getPaidMarksQuery().limit(2).get();
        const updatedPaidMarks = paidMarksDocs.docs.map((doc) => ({
            ...(doc.data() as IMarks),
            ref: doc.ref,
        }));
        setPayedMarks([...(payedMarks || []), ...updatedPaidMarks]);
        setLoading(false);
    };
    const payMarks = async (
        marks: IMarks & { ref: firebase.firestore.DocumentReference<any> },
        e: React.MouseEvent
    ) => {
        e.stopPropagation();
        setLoading(true);
        await marks.ref.update({ payed: !marks.payed });
        setLoading(false);
        await fetchMarks();
    };
    const displayingMarks = useMemo(() => (marks || []).concat(payedMarks || []), [marks, payedMarks]);
    const history = useHistory();
    useEffect(() => {
        fetchMarks();
    }, []);
    return (
        <Page onUpdate={fetchMarks} title="Strichliste" className={classNames(className)}>
            <Container className="marks-page">
                {marks && marks.length === 0 && (
                    <ul className="list">
                        <li className="font-crayon h4">Gratuliere, du hast keine unbezahlten Striche</li>
                        <li></li>
                        <li>&nbsp;</li>
                    </ul>
                )}
                {marks &&
                    displayingMarks.map((markEntry, idx) => (
                        <ul
                            onClick={() => history.push(`/${markEntry.type}/${markEntry.gameId}`)}
                            key={idx}
                            className="list font-crayon"
                        >
                            <li className="h4 d-flex flex-column">
                                <span className="ml-auto h5 text-secondary">{formatTimestampTime(markEntry.date)}</span>
                                <div>
                                    {!!markEntry.lostCoiffeur && "verlorener "}
                                    <span className="text-uppercase">{markEntry.type}</span>,
                                </div>
                                {formatTimestampDate(markEntry.date)}
                            </li>
                            <li className="text-right">Kosten pro Strich: {formatCHFAmount(markEntry.costsPerMark)}</li>
                            {!!markEntry.lostResults && (
                                <li className="marks-page__list-entry">kleine Niederlagen: {markEntry.lostResults}</li>
                            )}
                            {!!markEntry.matchAgainst && (
                                <li className="marks-page__list-entry">Match kassiert: {markEntry.matchAgainst}</li>
                            )}
                            {!!markEntry.counterMatch && (
                                <li className="marks-page__list-entry">
                                    Kontermatch kassiert: {markEntry.counterMatch}
                                </li>
                            )}
                            {!!markEntry.lostCoiffeur && (
                                <li className="marks-page__list-entry">Gesamtniederlage: {markEntry.lostCoiffeur}</li>
                            )}
                            <li>&nbsp;</li>
                            <li className="marks-page__list-entry">Total Striche: {markEntry.totalMarks}</li>
                            <li className="marks-page__list-entry font-weight-bold">
                                Betrag: <span className="text-highlight">{formatCHFAmount(markEntry.totalCosts)}</span>
                            </li>
                            <li className="d-flex" onClick={payMarks.bind(null, markEntry)}>
                                <div className="ml-auto text-1-4">
                                    <input type="checkbox" checked={markEntry.payed} />
                                    <span className="px-3">Bezahlt</span>
                                </div>
                            </li>
                            <li>&nbsp;</li>
                        </ul>
                    ))}
                <div className="d-flex justify-content-center">
                    <Button onClick={fetchPayedMarks}>Bezahlte Striche anzeigen</Button>
                </div>
                {loading && (
                    <ul className="list">
                        <li className="font-crayon h4">
                            <Skeleton count={1} />
                        </li>
                        <li>
                            <Skeleton count={1} />
                        </li>
                        <li>
                            <Skeleton count={1} />
                        </li>
                        <li>
                            <Skeleton count={1} />
                        </li>
                        <li>
                            <Skeleton count={1} />
                        </li>
                        <li>&nbsp;</li>
                    </ul>
                )}
            </Container>
        </Page>
    );
};
