import classNames from "classnames";
import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { ISchieber } from "../../api/domain/schieber";
import { ITeam } from "../../api/domain/team";
import { SchieberService } from "../../api/service/schieber-service";
import { SchieberTeam } from "../schieber-team/schieber-team";
import { SchieberSlateClickPane } from "./schieber-slate-click-pane/schieber-slate-click-pane";
import "./schieber-slate.scss";

interface IOwnProps {
    className?: string;
    schieber: ISchieber;
    addMark: (userIds: string[]) => Promise<void>;
    removeMark: (userIds: string[]) => Promise<void>;
    setWinner: (team: ITeam) => Promise<void>;
}

interface ISchieberSlateProps extends IOwnProps {}

export const SchieberSlate: React.FC<ISchieberSlateProps> = ({
    className,
    setWinner,
    schieber,
    removeMark,
    addMark,
}) => {
    const [teams] = useCollectionData<ITeam>(SchieberService.getSchieberTeamsRef(schieber.groupId, schieber.uid), {
        idField: "uid",
    });

    return (
        <div className={classNames(className, "schieber-slate w-100 h-100")}>
            <div className="w-100 h-100 slate-wrapper d-flex justify-content-center bg-wood">
                <div className={classNames(className, "slate w-100 h-100 bg-slate text-white position-relative")}>
                    {teams && teams.length === 2 && (
                        <>
                            <SchieberTeam
                                setWinner={setWinner}
                                addMark={addMark}
                                removeMark={removeMark}
                                schieber={schieber}
                                labelPosition="top"
                                className="fixed-top position-absolute"
                                team={teams[0]}
                            />
                            <SchieberTeam
                                setWinner={setWinner}
                                addMark={addMark}
                                removeMark={removeMark}
                                schieber={schieber}
                                labelPosition="bottom"
                                className="fixed-bottom position-absolute"
                                team={teams[1]}
                            />
                            <SchieberSlateClickPane
                                onClick={addMark}
                                team={teams[0]}
                                className="h-50 d-flex flex-column justify-content-end align-items-center pb-5"
                            ></SchieberSlateClickPane>
                            <div className="position-absolute ml-5 mr-5 w-75 schieber-slate__divider-line d-flex align-items-center justify-content-center font-crayon text-1-4"></div>
                            <SchieberSlateClickPane
                                onClick={addMark}
                                team={teams[1]}
                                className="h-50 d-flex flex-column justify-content-start align-items-center pt-5"
                            ></SchieberSlateClickPane>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
