import {
  IGroupSchieberSettings,
  IGroupCoiffeurSettings,
} from "../../api/domain/group-settings";
import { NotificationService } from "../../api/service/notification-service";
import { SettingsService } from "../../api/service/settings-service";
import { fetchGroups } from "./fetchGroups";

export const updateSchieberSettings = async (
  settings: Partial<IGroupSchieberSettings>,
  groupId: string
) => {
  try {
    await SettingsService.setOrUpdateSchieberSettings(settings, groupId);
    NotificationService.success("Einstellungen wurden angepasst");
  } catch (e) {
    console.debug(e);
    NotificationService.error("Einstellungen konnten nicht angepasst werden");
  }
};

export const updateCoiffeurSettings = async (
  settings: Partial<IGroupCoiffeurSettings>,
  groupId: string
) => {
  try {
    await SettingsService.setOrUpdateCoiffeurSettings(settings, groupId);
    NotificationService.success("Einstellungen wurden angepasst");
  } catch (e) {
    console.debug(e);
    NotificationService.error("Einstellungen konnten nicht angepasst werden");
  }
};

export const updateGroupName = async (name: string, groupId: string) => {
  if (!name || name.length > 25) {
    NotificationService.error(
      "Gruppenname darf nicht länger als 25 Zeichen sein"
    );
    return;
  }
  try {
    await SettingsService.updateGroupName(name, groupId);
    NotificationService.success("Gruppenname angepasst");
    await fetchGroups();
  } catch (e) {
    console.debug(e);
    NotificationService.error("Gruppenname konnte nicht angepasst werden");
  }
};
