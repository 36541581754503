import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { Button, Card } from "react-onsenui";
import { IUser } from "../../../api/domain";
import { firebase, firestore } from "../../../firebase";
import "./display-name-setting-card.scss";
import { updateUser } from "../../../store/updaters/updateUser";

interface IOwnProps {}

interface IDisplayNameSettingCardProps extends IOwnProps {}

export const DisplayNameSettingCard: React.FC<IDisplayNameSettingCardProps> = () => {
  const [firebaseUser] = useAuthState(firebase.auth());
  const documentPath = `users/${firebaseUser?.uid}`;
  const [value] = useDocument(firestore.doc(documentPath));
  let user: IUser | undefined = value?.data() as IUser;
  const userDisplayName = user?.displayName;
  const [displayName, setDisplayName] = useState("");
  useMemo(() => userDisplayName && setDisplayName(userDisplayName), [
    userDisplayName,
  ]);
  const updateDisplayNameInFirebase = () =>
    updateUser({
      displayName,
    });

  return (
    <Card>
      <div className="d-flex justify-content-center">
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="font-weight-bold">Anzeigename:</Form.Label>
          <Form.Control
            type="text"
            value={displayName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDisplayName(event.target.value);
            }}
            placeholder="Anzeigename"
          />
          <Form.Text className="text-muted">
            Wie du für andere sichtbar bist.
          </Form.Text>
        </Form.Group>
      </div>
      <Button
        disabled={displayName === (user && user.displayName)}
        onClick={updateDisplayNameInFirebase}
        modifier="large--cta"
      >
        Speichern
      </Button>
    </Card>
  );
};
