import { store } from "react-recollect";
import { firebase, firestore } from "../../firebase";
import { IUser } from "../domain";

export class UserService {
    static async updateProfile(updatedUser: Partial<IUser>) {
        const uid = firebase.auth().currentUser?.uid;
        const documentPath = `users/${uid}`;
        await firestore.doc(documentPath).update(updatedUser);
    }

    static async getUsers(userIds: string[]): Promise<IUser[]> {
        const cachedUsers = userIds.reduce((acc, userId) => {
            const cachedUser = store.users[userId];
            return cachedUser ? acc.concat(cachedUser) : acc;
        }, []);
        const promises = userIds
            .filter((userId) => !cachedUsers.some((user: IUser) => user.uid === userId))
            .reduce((acc, userId) => acc.concat(firestore.collection("users").doc(userId).get()), [] as Promise<any>[]);
        const users = await Promise.all(promises).then((users) => users.map((user) => user.data()));
        users.forEach((user: IUser) => {
            store.users[user.uid] = user;
        });
        return users.concat(cachedUsers);
    }

    static async getCurrentUser(): Promise<IUser> {
        const uid = firebase.auth().currentUser?.uid;
        const userDocument = await firestore.collection("users").doc(uid).get();
        const user: IUser = {
            displayName: "",
            ...userDocument.data(),
            uid: userDocument.id,
        };
        return user;
    }

    static async getUserById(userId: string): Promise<IUser> {
        const userDocument = await firestore.collection("users").doc(userId).get();
        const user: IUser = {
            displayName: "",
            ...userDocument.data(),
            uid: userDocument.id,
        };
        return user;
    }
}
