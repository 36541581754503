import React, { useMemo, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  Button,
  Icon,
  Input,
  ListHeader,
  ListItem,
  ProgressCircular,
} from "react-onsenui";
import { IReadGroup } from "../../../api/domain/group";
import { firestore } from "../../../firebase";
import "./group-name-settings.scss";
import { updateGroupName } from "../../../store/updaters/updateSettings";
import classNames from "classnames";

interface IOwnProps {
  groupId: string;
}

interface IGroupNameSettingsProps extends IOwnProps {}

export const GroupNameSettings: React.FC<IGroupNameSettingsProps> = ({
  groupId,
}) => {
  const [group, loading] = useDocumentData<IReadGroup>(
    firestore.collection("groups").doc(`${groupId}`)
  );
  const [localGroupName, setLocalGroupName] = useState<string | undefined>(
    group?.name
  );
  const updateMemoGroupName = () => group && setLocalGroupName(group.name);
  useMemo(updateMemoGroupName, [group?.name]);
  const onSave = () =>
    localGroupName && updateGroupName(localGroupName, groupId);
  const saveDisabled = group?.name === localGroupName;

  return !group || loading ? (
    <ProgressCircular indeterminate={true} />
  ) : (
    <>
      <ListHeader className="py-3">Gruppenname</ListHeader>
      <ListItem className="pb-4">
        <div className="center">
          <Input
            placeholder="Gruppenname"
            disabled={loading}
            value={localGroupName}
            onChange={(e) => setLocalGroupName(e.target.value)}
          />
        </div>

        <div className="right">
          <Button
            onClick={onSave}
            className={classNames(!saveDisabled && "bg-success text-white")}
            disabled={saveDisabled}
          >
            <Icon icon="md-save" />
          </Button>
        </div>
      </ListItem>
    </>
  );
};
