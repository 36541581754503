import { CoiffeurService } from "../../api/service/coiffeur-service";
import { NotificationService } from "../../api/service/notification-service";

export const deleteCoiffeurResult = async ({
    coiffeurId,
    groupId,
    teamId,
    opponentTeamId,
    disciplineId,
}: {
    coiffeurId: string;
    groupId: string;
    teamId: string;
    opponentTeamId: string;
    disciplineId: string;
}) => {
    try {
        await CoiffeurService.deleteCoiffeurResult(coiffeurId, groupId, teamId, opponentTeamId, disciplineId);
        NotificationService.infoTop("Spiel gelöscht");
    } catch (e) {
        console.debug("Could not create group");
        console.debug(e);
        NotificationService.error("Spiel konnte nicht gelöscht werden");
    }
};
