import classNames from "classnames";
import React from "react";
import Slider from "react-input-slider";
import "./choose-points.scss";

interface IOwnProps {
    className?: string;
    points: number;
    setPoints: (value: number) => void;
    match: boolean;
    setMatch: (value: boolean) => void;
    counterMatch: boolean;
    setCounterMatch: (value: boolean) => void;
}

interface IChoosePointsProps extends IOwnProps {}

export const ChoosePoints: React.FC<IChoosePointsProps> = ({
    className,
    points,
    setPoints,
    match,
    setMatch,
    counterMatch,
    setCounterMatch,
}) => {
    const onMatch = () => {
        setPoints(16);
        setMatch(!match);
        setCounterMatch(false);
    };
    const onCounterMatch = () => {
        setPoints(0);
        setMatch(false);
        setCounterMatch(!counterMatch);
    };
    const onPointsChange = (points: number) => {
        if (points !== 16) {
            setMatch(false);
        }
        if (points !== 0) {
            setCounterMatch(false);
        }
        setPoints(points);
    };

    return (
        <div className={classNames(className, "choose-points")}>
            <div className="w-100">
                <div className="d-flex p-4 justify-content-between align-items-center">
                    <div className="choose-points__digit text-white">
                        {points}
                        {(match || counterMatch) && <span>*</span>}
                    </div>
                    <div className="choose-points__checkbox-match text-white d-flex flex-column font-weight-bold">
                        <div className="d-flex flex-column">
                            <div className="text-left">Match</div>
                            <div className="pt-1">
                                <input readOnly={true} type="checkbox" checked={match} />
                                <label onClick={onMatch} />
                            </div>
                        </div>
                        <div
                            className={classNames(
                                "choose-points__checkbox-counter-match d-flex flex-column pt-3",
                                !counterMatch && "choose-points__checkbox--inactive"
                            )}
                        >
                            <div>Kontermatch</div>
                            <div className="pt-1">
                                <input readOnly={true} type="checkbox" checked={counterMatch} />
                                <label onClick={onCounterMatch} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4" />
                <Slider
                    axis="x"
                    x={points}
                    onChange={({ x }) => onPointsChange(x)}
                    xmax={16}
                    xmin={0}
                    styles={{
                        active: {
                            backgroundColor: points < 10 ? "red" : "lime",
                        },
                        track: {
                            opacity: 0.8,
                            width: "60%",
                        },
                        thumb: {
                            width: 40,
                            height: 40,
                        },
                    }}
                />
            </div>
        </div>
    );
};
