import { IResult } from "../../api/domain/result";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { NotificationService } from "../../api/service/notification-service";

export const addCoiffeurResult = async (
    coiffeurId: string,
    groupId: string,
    teamId: string,
    opponentTeamId: string,
    { player, match, multiplier, disciplineId, counterMatch, points }: IResult
) => {
    try {
        await CoiffeurService.addCoiffeurResult(coiffeurId, groupId, teamId, opponentTeamId, {
            points,
            disciplineId,
            player,
            multiplier,
            match,
            counterMatch,
        });
        NotificationService.successTop("Spiel gespeichert");
    } catch (e) {
        console.error(e);
        NotificationService.errorTop("Spiel konnte nicht gespeichert werden");
    }
};
