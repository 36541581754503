import mdColorMappings from "@react-md/theme/dist/scssVariables";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { Button, Icon, ListTitle } from "react-onsenui";
import { collect, WithStoreProp } from "react-recollect";
import { ReactSortable } from "react-sortablejs";
import { ICoiffeurConfiguration, IDisciplineConfiguration } from "../../api/domain/coiffeur-configuration";
import { IDiscipline } from "../../api/domain/discipline";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { DisciplineImage, DisciplineImageSize } from "../discipline-image/discipline-image";
import "./coiffeur-configurator.scss";

interface IOwnProps {
    className?: string;
    onSave: (configuration: ICoiffeurConfiguration) => void;
    saveLabel?: string;
}

interface ICoiffeurConfiguratorProps extends IOwnProps, WithStoreProp {}

const colorMappings: string[] = [];
for (let i = 0; i < 12; i++) {
    const shade = Math.min(Math.max((i - 2) * 100, 50), 900);
    const shadeKey = i < 5 ? `rmd-green-${shade}` : `rmd-yellow-${shade}`;
    // @ts-ignore
    const color: string = mdColorMappings[shadeKey];
    colorMappings.push(color);
}

const InternalCoiffeurConfigurator: React.FC<ICoiffeurConfiguratorProps> = ({
    className,
    store,
    onSave,
    saveLabel,
}) => {
    const [disciplines, setDisciplines] = useState<(IDiscipline & { id: number })[]>([]);
    const [unusedDisciplines, setUnusedDisciplines] = useState<(IDiscipline & { id: number })[]>([]);
    useEffect(() => {
        setUnusedDisciplines(
            store.disciplines
                .filter((discipline) => !disciplines.some((d) => d.uid === discipline.uid))
                .map((discipline, idx) => ({
                    ...discipline,
                    id: idx,
                }))
        );
    }, [store.disciplines, disciplines]);
    useEffect(() => {
        CoiffeurService.getLatestCoiffeurDisciplines().then((disciplines) => {
            setDisciplines(disciplines);
        });
    }, [store.disciplines]);

    const removeDiscipline = (uid: string) => {
        const discipline = disciplines.find((d) => d.uid === uid);
        if (!discipline) {
            return;
        }
        setUnusedDisciplines([discipline, ...unusedDisciplines]);
        setDisciplines(disciplines.filter((d) => d !== discipline));
    };
    const getBadgeColor = (idx: number) => colorMappings[idx] || colorMappings[11];
    const save = () =>
        onSave(
            disciplines.reduce(
                (acc, { name, uid }, idx) => ({
                    ...acc,
                    [uid]: {
                        multiplier: idx + 1,
                        name,
                    } as IDisciplineConfiguration,
                }),
                {}
            )
        );

    return (
        <div className={classNames(className, "coiffeur-configurator")}>
            <ListTitle>Coiffeur Konfiguration</ListTitle>
            <ReactSortable group="test" animation={150} handle=".handle" list={disciplines} setList={setDisciplines}>
                {disciplines.map((discipline, idx) => (
                    <div key={discipline.uid} className="bg-white py-1 pr-2 border-bottom d-flex align-items-center">
                        <Icon icon="md-menu" className="handle px-3 py-1 h4 text-secondary" />
                        <div className="handle h5 mr-2">
                            {/*
                            // @ts-ignore*/}
                            <Badge style={{ backgroundColor: getBadgeColor(idx) }}>
                                {idx + 1} <Icon icon="fa-times" />
                            </Badge>
                        </div>
                        <DisciplineImage
                            className="handle"
                            inverted={true}
                            disciplineId={discipline.uid}
                            size={DisciplineImageSize.EXTRA_SMALL}
                        />
                        <div className="flex-grow-1 text-center">{discipline.name}</div>
                        <div className="ml-auto py-2 px-3" onClick={removeDiscipline.bind(null, discipline.uid)}>
                            <Icon icon="fa-minus-circle" className="text-danger" />
                        </div>
                    </div>
                ))}
            </ReactSortable>
            <ListTitle>Weitere Disziplinen</ListTitle>
            <ReactSortable
                handle=".handle"
                group="test"
                animation={50}
                list={unusedDisciplines}
                setList={setUnusedDisciplines}
            >
                {unusedDisciplines.map((discipline, idx) => (
                    <div className="py-1 pr-2 border-bottom d-flex align-items-center" key={discipline.uid}>
                        <Icon icon="md-menu" className="handle px-3 py-1 h4 text-secondary handle" />
                        <DisciplineImage
                            className="handle"
                            inverted={true}
                            disciplineId={discipline.uid}
                            size={DisciplineImageSize.EXTRA_SMALL}
                        />
                        <div className="flex-grow-1 text-center">{discipline.name}</div>
                    </div>
                ))}
            </ReactSortable>
            <div className="p-5"></div>
            <div className={classNames("p-4 position-fixed w-100 create-coiffeur-button")} style={{ bottom: 0 }}>
                <Button onClick={save} disabled={disciplines.length <= 0} modifier="large--cta">
                    {saveLabel || "Übernehmen"}
                </Button>
            </div>
        </div>
    );
};

export const CoiffeurConfigurator = collect(InternalCoiffeurConfigurator);
