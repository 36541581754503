import classNames from "classnames";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Icon } from "react-onsenui";
import { collect, WithStoreProp } from "react-recollect";
import { IUser } from "../../api/domain";
import { IResult } from "../../api/domain/result";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { formatTimestampDate, formatTimestampTime } from "../../util/date-helpers";
import { DisciplineImage, DisciplineImageSize } from "../discipline-image/discipline-image";
import "./latest-activity-list.scss";

interface IOwnProps {
    className?: string;
    user: IUser;
}

interface ILatestActivityListProps extends IOwnProps, WithStoreProp {}
const getColor = (points: number, winner?: boolean, tie?: boolean) => {
    const percentage = (points * 100) / 16;
    const contrary = 100 - percentage;
    return winner
        ? `rgba(51, 153, 102, ${Math.max(0.4, percentage / 100)})`
        : tie
        ? `rgba(0, 0, 0, ${Math.max(0.4, percentage / 100)})`
        : `rgba(255,0,0, ${Math.max(0.4, contrary / 100)})`;
};

export const InternalLatestActivityList: React.FC<ILatestActivityListProps> = ({ className, user, store }) => {
    const [results, loading] = useCollectionData<IResult>(CoiffeurService.getResultsRef(user.uid, 24));
    useEffect(() => {
        store.isLoading = loading;
        // eslint-disable-next-line
    }, [loading]);

    return (
        <Container className={classNames(className, "latest-activity-list px-3")}>
            {results &&
                results.map((result, idx) => (
                    <div key={idx}>
                        <Row className="d-flex py-3 justify-content-between align-items-center" key={idx}>
                            <Col>
                                <DisciplineImage
                                    inverted={true}
                                    disciplineId={result.disciplineId}
                                    size={DisciplineImageSize.EXTRA_SMALL}
                                />
                            </Col>
                            <Col className="d-flex flex-column text-secondary font-crayon">
                                <div>{result.date && formatTimestampDate(result.date)}</div>
                                <div>{result.date && formatTimestampTime(result.date)}</div>
                            </Col>
                            <Col className="font-crayon text-1-4 d-flex justify-content-center">
                                <CircularProgressbar
                                    className="progress-circle"
                                    styles={buildStyles({
                                        strokeLinecap: "butt",
                                        textSize: "45px",
                                        pathColor: getColor(
                                            result.points,
                                            result.won,
                                            result.points === result.opponentResult?.points
                                        ),
                                        textColor: getColor(
                                            result.points,
                                            result.won,
                                            result.points === result.opponentResult?.points
                                        ),
                                        trailColor: "rgba(137,137,137,0.2)",
                                    })}
                                    strokeWidth={3}
                                    text={`${result.points}${result.match ? "*" : ""}`}
                                    value={result.points}
                                    minValue={0}
                                    maxValue={16}
                                />
                            </Col>
                            <Col>
                                {result.opponentResult && (
                                    <div className="d-flex flex-column justify-content-center align-items-center text-secondary font-crayon">
                                        <span>vs.</span>
                                        {result.opponentResult.points}
                                        {result.opponentResult.match && "*"}
                                    </div>
                                )}
                            </Col>

                            <Col>
                                {result.won && <Icon className="text-success" icon="fa-thumbs-up" />}
                                {result.lostOrTied && result.points !== result.opponentResult?.points && (
                                    <Icon className="text-danger" icon="fa-thumbs-down" />
                                )}
                                {result.points === result.opponentResult?.points && <Icon icon="fa-hand-point-right" />}
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-center">
                            <div className="border-bottom w-50" />
                        </div>
                    </div>
                ))}
        </Container>
    );
};

export const LatestActivityList = collect(InternalLatestActivityList);
