import firebase from "firebase";
import { firestore } from "../../firebase";
import { IUser } from "../domain";
import { ICoiffeur } from "../domain/coiffeur";
import { IResolvedGroup } from "../domain/group";
import { IGroupSchieberSettings } from "../domain/group-settings";
import { ISchieber, ISchieberWithTeams } from "../domain/schieber";
import { ITeam } from "../domain/team";

export class SchieberService {
    static async deleteSchieber(schieberId: string) {
        const batch = firestore.batch();
        const schieberGames = await firestore.collectionGroup("schieber").where("uid", "==", schieberId).get();
        if (schieberGames.size === 0) {
            throw new Error(`Schieber with id ${schieberId} not found`);
        }
        const marks = await schieberGames.docs[0].ref.collection("marks").get();
        const teams = await schieberGames.docs[0].ref.collection("teams").get();
        marks.forEach((doc) => batch.delete(doc.ref));
        teams.forEach((doc) => batch.delete(doc.ref));
        batch.delete(schieberGames.docs[0].ref);
        await batch.commit();
    }

    static getOngoingSchieberGamesQuery(): firebase.firestore.Query {
        const uid = firebase.auth().currentUser?.uid;
        return firestore
            .collectionGroup("schieber")
            .where("completed", "==", false)
            .where(`members`, "array-contains", uid)
            .orderBy("date", "desc");
    }

    static async getSchieberWithTeams(
        limit: number = 2,
        startAfter: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now()
    ): Promise<ISchieberWithTeams[]> {
        const uid = firebase.auth().currentUser?.uid;
        const gamesDocs = await firestore
            .collectionGroup("schieber")
            .where(`members`, "array-contains", uid)
            .orderBy("date", "desc")
            .limit(limit)
            .startAfter(startAfter)
            .get();
        const schieberGames = await gamesDocs.docs.map((games) => games.data() as ICoiffeur);
        const teamsRefs = gamesDocs.docs.map((gameRef) => gameRef.ref.collection("teams").get());
        const teamDocs = await Promise.all(teamsRefs);

        return schieberGames.map(
            (game, idx) =>
                ({
                    ...game,
                    teams: teamDocs[idx].docs.map((doc) => doc.data() as ITeam),
                } as ISchieberWithTeams)
        );
    }

    static getLatestSchieberGamesQuery(max: number, userId?: string): firebase.firestore.Query {
        const uid = userId || firebase.auth().currentUser?.uid;
        return firestore
            .collectionGroup("schieber")
            .where("completed", "==", true)
            .where(`members`, "array-contains", uid)
            .orderBy("date", "desc")
            .limit(max);
    }

    static getSchieberUserMarksRef(
        groupId: string,
        schieberId: string,
        userId: string
    ): firebase.firestore.DocumentReference {
        return firestore
            .collection("games")
            .doc(groupId)
            .collection("schieber")
            .doc(schieberId)
            .collection("marks")
            .doc(userId);
    }

    static getSchieberTeamsRef(groupId: string, schieberId: string): firebase.firestore.Query {
        const teamsRef = firestore
            .collection("games")
            .doc(groupId)
            .collection("schieber")
            .doc(schieberId)
            .collection("teams")
            .orderBy("order", "asc");
        return teamsRef;
    }

    static async getGroupSchieberSettings(groupId: string): Promise<IGroupSchieberSettings | undefined> {
        const groupSchieberSettingsDoc = await firestore
            .collection("groups")
            .doc(groupId)
            .collection("settings")
            .doc("schieber")
            .get();
        return groupSchieberSettingsDoc.data() as IGroupSchieberSettings;
    }

    static async getSchieber(schieberId: string): Promise<ISchieber> {
        const schieberGames = await firestore.collectionGroup("schieber").where("uid", "==", schieberId).get();
        if (schieberGames.size === 0) {
            throw new Error(`Schieber with id ${schieberId} not found`);
        }
        const schieberRef = schieberGames.docs[0];
        const data = schieberRef.data();
        return {
            ...data,
        } as ISchieber;
    }

    static async setSchieberWinner({
        schieberId,
        groupId,
        team,
    }: {
        groupId: string;
        schieberId: string;
        team?: ITeam;
    }): Promise<void> {
        let batch = firestore.batch();
        const lastEditor = firebase.auth().currentUser?.uid;
        const schieberRef = await firestore.collection("games").doc(groupId).collection("schieber").doc(schieberId);
        batch.update(schieberRef, { completed: true });
        batch.update(schieberRef, { lastEdit: firebase.firestore.Timestamp.now() });
        batch.update(schieberRef, { lastEditor });
        if (!team) {
            batch.update(schieberRef, { winners: firebase.firestore.FieldValue.delete() });
        } else {
            batch.update(schieberRef, { winners: team.members });
        }

        await batch.commit();
    }

    static async createSchieber(group: IResolvedGroup, teams: IUser[][]): Promise<string> {
        const lastEditor = firebase.auth().currentUser?.uid;
        const groupMembers = group.members.reduce(
            (acc, val) => ({
                ...acc,
                [val.uid]: true,
            }),
            {}
        );
        const teamMembers = teams.flat().map((user) => user.uid);
        let batch = firestore.batch();
        const date = firebase.firestore.Timestamp.now();
        const gamesGroupRef = firestore.collection("games").doc(group.uid);
        const createdSchieberRef = await gamesGroupRef.collection("schieber").add({
            date,
            completed: false,
            members: teamMembers,
            groupId: group.uid,
            lastEdit: date,
            lastEditor,
        });
        batch.update(createdSchieberRef, { uid: createdSchieberRef.id });
        batch.set(gamesGroupRef, { members: groupMembers });
        teams.forEach((team, idx) => {
            const teamId = [...team.map((m) => m.uid)].sort().reduce((acc, userId) => `${acc}${userId}`, "");
            const members = team.reduce(
                (acc, val) => ({
                    ...acc,
                    [val.uid]: { ...val },
                }),
                {}
            );
            const teamRef = createdSchieberRef.collection("teams").doc(teamId);
            batch.set(teamRef, { members, order: idx });
        });
        await batch.commit();
        return createdSchieberRef.id;
    }
}
