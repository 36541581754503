import React from "react";
import { ActiveGroupSettingCard } from "../../components/cards/active-group-setting-card/active-group-setting-card";
import { DisplayNameSettingCard } from "../../components/cards/display-name-setting-card/display-name-setting-card";
import { UserCard } from "../../components/cards/user-card/user-card";
import { UserImageSettingCard } from "../../components/cards/user-image-setting-card/user-image-setting-card";
import { Page } from "../../components/page/page";
import "./profile-page.scss";

interface IOwnProps {}

interface IProfilePageProps extends IOwnProps {}

export const ProfilePage: React.FC<IProfilePageProps> = () => {
  return (
    <Page title="Profil">
      <UserCard />
      <DisplayNameSettingCard />
      <ActiveGroupSettingCard />
      <UserImageSettingCard />
    </Page>
  );
};
