import classNames from "classnames";
import React, { useMemo } from "react";
import "./coiffeur-per-team-statistics.scss";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { IGroupStatistics, IUser } from "../../../api/domain";
import { firestore } from "../../../firebase";
import { store } from "react-recollect";
import { Card } from "react-onsenui";
import { Bar, CartesianGrid, ComposedChart, Label, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";

interface IOwnProps {
    className?: string;
    groupId: string;
}

interface ICoiffeurPerTeamStatisticsProps extends IOwnProps {}

export const CoiffeurPerTeamStatistics: React.FC<ICoiffeurPerTeamStatisticsProps> = ({ className, groupId }) => {
    const [groupStatistics] = useDocumentData<IGroupStatistics>(firestore.collection("statistics").doc(groupId));
    const highestTotalGames = Object.values(groupStatistics?.coiffeurStatsPerTeam || {}).reduce(
        (acc, val) => Math.max(acc, val.totalGames),
        0
    );
    const data = useMemo(
        () =>
            Object.values(groupStatistics?.coiffeurStatsPerTeam || {})
                .map((coiffeurStatsPerTeam) => ({
                    displayName: coiffeurStatsPerTeam.members
                        .map((memberId) => (store.users[memberId] as IUser)?.displayName || memberId)
                        .join(" & "),
                    winCount: coiffeurStatsPerTeam.winCount,
                    winCountRight: `${coiffeurStatsPerTeam.winCount} gewonnen`,
                    lossCount: coiffeurStatsPerTeam.lossCount,
                    lossCountRight: `${coiffeurStatsPerTeam.lossCount} verloren`,
                    totalGames: coiffeurStatsPerTeam.totalGames,
                    totalGamesRight: `${coiffeurStatsPerTeam.totalGames} Spiele Total`,
                    teamNameDataPoint: 100,
                    winPercentage: Math.round((coiffeurStatsPerTeam.winCount * 100) / coiffeurStatsPerTeam.totalGames),
                    winPercentageLabel: `${Math.round(
                        (coiffeurStatsPerTeam.winCount * 100) / coiffeurStatsPerTeam.totalGames
                    )} %`,
                    winPercentageRight: `${coiffeurStatsPerTeam.winCount} von ${coiffeurStatsPerTeam.totalGames}`,
                    lossPercentage: Math.round(
                        (coiffeurStatsPerTeam.lossCount * 100) / coiffeurStatsPerTeam.totalGames
                    ),
                    lossPercentageLabel: `${Math.round(
                        (coiffeurStatsPerTeam.lossCount * 100) / coiffeurStatsPerTeam.totalGames
                    )} %`,
                    lossPercentageRight: `${coiffeurStatsPerTeam.lossCount} von ${coiffeurStatsPerTeam.totalGames}`,
                }))
                .sort(
                    (matchesPerTeam1, matchesPerTeam2) => matchesPerTeam2.winPercentage - matchesPerTeam1.winPercentage
                ),
        [groupStatistics]
    );
    return data?.length ? (
        <Card className={classNames(className, "coiffeur-per-team-statistics")}>
            <b>Coiffeur</b>
            <ResponsiveContainer width="100%" height={650} className="coiffeur-per-team-statistics-body">
                <ComposedChart
                    layout="vertical"
                    data={data}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    barGap={3}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis
                        xAxisId="percentage"
                        type="number"
                        domain={[0, 100]}
                        tickLine={false}
                        axisLine={false}
                        interval={0}
                        minTickGap={0}
                        unit=" %"
                    >
                        <Label fill="rgba(0,0,0,.3)" value="Gewinnquote" offset={0} position="insideBottom" />
                    </XAxis>
                    <XAxis
                        xAxisId="numberOfGames"
                        type="number"
                        domain={[0, highestTotalGames + 8]}
                        tickLine={false}
                        axisLine={false}
                        interval={0}
                        minTickGap={0}
                        orientation="top"
                    >
                        <Label fill="rgba(0,0,0,.5)" value="Spiele" offset={0} position="insideTop" />
                    </XAxis>
                    <YAxis interval={0} type="category" dataKey="label" tickLine={false} axisLine={false} hide={true} />
                    <Bar
                        radius={2}
                        xAxisId="percentage"
                        barSize={20}
                        isAnimationActive={false}
                        dataKey="teamNameDataPoint"
                        fill="white"
                    >
                        <LabelList
                            formatter={(val: any) => val.toLocaleString().replace(/\s/g, "\u00A0")}
                            className="bar-label bar-label-bold"
                            dataKey="displayName"
                            position="insideBottomLeft"
                        />
                    </Bar>

                    <Bar
                        xAxisId="percentage"
                        barSize={15}
                        isAnimationActive={false}
                        dataKey="winPercentage"
                        stackId="winloss"
                        fill="rgb(87, 167, 115)"
                    >
                        <LabelList
                            className="bar-label bar-label-white"
                            dataKey="winPercentageLabel"
                            position="inside"
                        />
                    </Bar>
                    <Bar
                        xAxisId="percentage"
                        barSize={15}
                        isAnimationActive={false}
                        dataKey="lossPercentage"
                        stackId="winloss"
                        fill="rgb(238, 99, 82)"
                    >
                        <LabelList
                            className="bar-label bar-label-white"
                            dataKey="lossPercentageLabel"
                            position="inside"
                        />
                    </Bar>
                    <Bar
                        radius={[2, 5, 5, 2]}
                        xAxisId="numberOfGames"
                        barSize={7}
                        isAnimationActive={false}
                        dataKey="totalGames"
                        fill="#8d8d8d"
                    >
                        <LabelList className="bar-label bar-label-gray" dataKey="totalGamesRight" position="right" />
                    </Bar>
                    <Bar
                        radius={[2, 5, 5, 2]}
                        xAxisId="numberOfGames"
                        barSize={7}
                        isAnimationActive={false}
                        dataKey="winCount"
                        fill="#32CD32"
                    >
                        <LabelList className="bar-label bar-label-green" dataKey="winCountRight" position="right" />
                    </Bar>
                    <Bar
                        radius={[2, 5, 5, 2]}
                        xAxisId="numberOfGames"
                        barSize={7}
                        isAnimationActive={false}
                        dataKey="lossCount"
                        fill="#df1313"
                    >
                        <LabelList className="bar-label bar-label-red" dataKey="lossCountRight" position="right" />
                    </Bar>
                </ComposedChart>
            </ResponsiveContainer>
        </Card>
    ) : (
        <Card>Noch keine Daten für Coiffeur Gesamtwertung vorhanden</Card>
    );
};
