import { IUser } from "../../api/domain";
import { NotificationService } from "../../api/service/notification-service";
import { UserService } from "../../api/service/user-service";

export const updateUser = async (updatedUser: Partial<IUser>) => {
  try {
    await UserService.updateProfile(updatedUser);
    NotificationService.success("Profil wurde angepasst");
  } catch (e) {
    console.debug(e);
    NotificationService.error("Profil konnte nicht angepasst werden");
  }
};
