import classNames from "classnames";
import * as H from "history";
import React from "react";
import { Icon, ProgressCircular } from "react-onsenui";
import { IUser } from "../../api/domain";
import { useUserImage } from "../../hooks/use-user-image";
import "./user-image.scss";

export enum UserImageSize {
    LARGE = "lg",
    MEDIUM = "md",
    SMALL = "sm",
    EXTRA_SMALL = "xs",
    TINY = "xxs",
}

interface IOwnProps {
    user?: IUser;
    className?: string;
    classNameImage?: string;
    size: UserImageSize;
    rounded?: boolean;
    innerStyle?: React.CSSProperties;
    openProfileOnClick?: boolean;
    history?: H.History;
}

interface IUserImageProps extends IOwnProps {}

export const UserImage: React.FC<IUserImageProps> = ({
    className,
    innerStyle,
    classNameImage,
    user,
    size,
    rounded,
    openProfileOnClick,
    history,
}) => {
    const [displayImageUrl, loading, error] = useUserImage(user);
    const style = (!loading && !error && { backgroundImage: `url(${displayImageUrl})`, ...innerStyle }) || undefined;

    const openProfile = (e: React.MouseEvent) => {
        if (openProfileOnClick && history) {
            e.stopPropagation();
            history.push(`/profil/${user?.uid}`);
        }
    };

    return (
        <picture onClick={openProfile} className={classNames(className, "user-image d-flex justify-content-center")}>
            <div
                className={classNames(
                    classNameImage,
                    `user-image__preview user-image__preview--${size} d-flex justify-content-center align-items-center`,
                    rounded && "user-image__preview--rounded"
                )}
                style={style}
            >
                {!displayImageUrl && !loading && <Icon className={`user-image__fallback--${size}`} icon="md-face" />}
                {loading && <ProgressCircular indeterminate={true} />}
            </div>
        </picture>
    );
};
