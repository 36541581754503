import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { List, ListHeader, ListItem } from "react-onsenui";
import { IUser } from "../../api/domain";
import { IResolvedGroup } from "../../api/domain/group";
import { useGroups } from "../../hooks/use-groups";
import { useUser } from "../../hooks/use-user";
import { ActiveGroupSettingCard } from "../cards/active-group-setting-card/active-group-setting-card";
import { UserListItem } from "../list-items/user-list-item/user-list-item";
import "./team-configurator.scss";

interface IOwnProps {
    className?: string;
    onSave?: (group: IResolvedGroup, teams: IUser[][]) => void;
    teams: IUser[][];
}

interface ITeamConfiguratorProps extends IOwnProps {}

export const TeamConfigurator: React.FC<ITeamConfiguratorProps> = ({ className, onSave, teams }) => {
    const [user, , , fetchUser] = useUser();
    const [groups] = useGroups();
    const defaultGroupId = user?.defaultGroupId;
    const selectedGroup = useMemo(
        () => (groups.length === 1 ? groups[0] : groups.find(({ uid }) => uid === defaultGroupId)),
        [defaultGroupId, groups]
    );
    const groupMembers = useMemo(() => (selectedGroup ? selectedGroup.members : []), [selectedGroup]);
    const [team1, setTeam1] = useState<IUser[]>(teams[0] || []);
    const [team2, setTeam2] = useState<IUser[]>(teams[1] || []);
    const unselectedPlayers = groupMembers.filter(
        (p) => !team1.some(({ uid }) => uid === p.uid) && !team2.some(({ uid }) => uid === p.uid)
    );
    useEffect(() => {
        if (onSave && team1.length === 2 && team2.length === 2 && selectedGroup) {
            onSave(selectedGroup, [
                team1.map(({ uid }) => groupMembers.find((m) => m.uid === uid)!),
                team2.map(({ uid }) => groupMembers.find((m) => m.uid === uid)!),
            ]);
        } else if (onSave && selectedGroup) {
            onSave(selectedGroup, []);
        }
        // eslint-disable-next-line
    }, [team1, team2]);
    useEffect(() => {
        setTeam1([]);
        setTeam2([]);
        onSave && selectedGroup && onSave(selectedGroup, []);
        // eslint-disable-next-line
    }, [groupMembers]);
    const addToTeam = (user: IUser) => {
        if (team1.length < 2) {
            setTeam1([...team1, user]);
        } else {
            setTeam2([...team2, user]);
        }
    };
    const removePlayer = (user: IUser) => {
        setTeam1(team1.filter(({ uid }) => uid !== user.uid));
        setTeam2(team2.filter(({ uid }) => uid !== user.uid));
    };

    return (
        <div className={classNames(className, "team-configurator")}>
            <ActiveGroupSettingCard onUpdate={fetchUser} label="Jassgruppe" />
            <List>
                <ListHeader>Team 1</ListHeader>
                {team1.map((member) =>
                    member ? (
                        <UserListItem onClick={removePlayer} key={member.uid} user={member} />
                    ) : (
                        <ListItem>
                            <Skeleton count={2} />
                        </ListItem>
                    )
                )}
                <ListHeader>Team 2</ListHeader>
                {team2.map((member) =>
                    member ? (
                        <UserListItem onClick={removePlayer} key={member.uid} user={member} />
                    ) : (
                        <ListItem>
                            <Skeleton count={2} />
                        </ListItem>
                    )
                )}
                {unselectedPlayers.length > 0 && <ListHeader>Restliche Spieler</ListHeader>}
                {groupMembers.length > 0
                    ? unselectedPlayers.map((member) => (
                          <UserListItem onClick={addToTeam} key={member.uid} user={member} />
                      ))
                    : Array(4)
                          .fill(null)
                          .map((val, idx) => (
                              <div className="bg-white p-3 border-bottom" key={idx}>
                                  <Skeleton count={2} />
                              </div>
                          ))}
            </List>
        </div>
    );
};
