import classNames from "classnames";
import React from "react";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Button, ListTitle, Select } from "react-onsenui";
import { useHistory } from "react-router";
import { useGroups } from "../../../hooks/use-groups";
import { useUser } from "../../../hooks/use-user";
import "./active-group-setting-card.scss";

interface IOwnProps {
    className?: string;
    label?: string;
    onUpdate?: () => void;
}

interface IActiveGroupSettingCardProps extends IOwnProps {}

export const ActiveGroupSettingCard: React.FC<IActiveGroupSettingCardProps> = ({ className, label, onUpdate }) => {
    const [groups, loadingGroups] = useGroups();
    const [user, , , , updateUser] = useUser();
    const history = useHistory();

    const updateDefaultGroupId = async ({
        target: { value: defaultGroupId },
    }: React.ChangeEvent<HTMLSelectElement>) => {
        user &&
            (await updateUser({
                defaultGroupId,
            }));
        onUpdate && onUpdate();
    };
    return (
        <Card className={classNames(className, "active-group-setting-card")}>
            <ListTitle>{label || "Aktive Jassgruppe"}</ListTitle>
            {loadingGroups && <Skeleton count={2} />}
            {!loadingGroups && (
                <Select value={user?.defaultGroupId} onChange={updateDefaultGroupId} className="w-100 p-2">
                    {groups.map((group) => (
                        <option key={group.uid} value={group.uid} className="active-group-setting-card__option">
                            {group.name}
                        </option>
                    ))}
                </Select>
            )}
            {groups && groups.length === 0 && (
                <Button onClick={() => history.push("/neue-jassgruppe")}>Jassgruppe erstellen</Button>
            )}
        </Card>
    );
};
