import * as firebase from "firebase/app";
import { createBrowserHistory } from "history";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { store } from "react-recollect";
import { Route, Router as ReactRouter, Switch } from "react-router-dom";
import { AllGamesPage } from "../../pages/all-games-page/all-games-page";
import { CoiffeurPage } from "../../pages/coiffeur-page/coiffeur-page";
import { CreateCoiffeurPage } from "../../pages/create-coiffeur-page/create-coiffeur-page";
import { CreateSchieberPage } from "../../pages/create-schieber-page/create-schieber-page";
import { CreateGroupPage } from "../../pages/groups-page/create-group-page/create-group-page";
import { GroupSettingsPage } from "../../pages/groups-page/group-settings-page/group-settings-page";
import { GroupsPage } from "../../pages/groups-page/groups-page";
import { Jasskasse } from "../../pages/jasskasse/jasskasse";
import { LoginPage } from "../../pages/login-page";
import { ProfilePage } from "../../pages/profile-page/profile-page";
import { PublicProfilePage } from "../../pages/public-profile-page/public-profile-page";
import { SchieberPage } from "../../pages/schieber-page/schieber-page";
import { StatisticsPage } from "../../pages/statistics-page/statistics-page";
import { WelcomePage } from "../../pages/welcome-page/welcome-page";
import { SplashScreen } from "../splash-screen/splash-screen";
import "./router.scss";
import { MarksPage } from "../../pages/marks-page/marks-page";

interface IOwnProps {
    className?: string;
}

interface IRouterProps extends IOwnProps {}

const history = createBrowserHistory();
history.listen(() => {
    store.isMenuOpen = false;
});

export const Router: React.FC<IRouterProps> = () => {
    const [user, loading] = useAuthState(firebase.auth());

    return (
        <ReactRouter history={history}>
            <Switch>
                {/*TODO: DELETE NEXT LINE */}
                {/*<Route path="/" component={MarksPage} />*/}
                {/*TODO: DELETE PREVIOUS LINE */}

                {loading && <Route path="/" component={SplashScreen} />}
                {user && (
                    <>
                        <Route path="/" exact={true} component={WelcomePage} />
                        <Route path="/alle-spiele" component={AllGamesPage} />
                        <Route path="/jasskasse" component={Jasskasse} />
                        <Route path="/profil/:id" component={PublicProfilePage} />
                        <Route path="/coiffeur/:id" component={CoiffeurPage} />
                        <Route path="/schieber/:id" component={SchieberPage} />
                        <Route path="/neuer-schieber" component={CreateSchieberPage} />
                        <Route path="/neuer-coiffeur" component={CreateCoiffeurPage} />
                        <Route path="/neue-jassgruppe" component={CreateGroupPage} />
                        <Route path="/strichliste" component={MarksPage} />
                        <Route path="/jassgruppe-einstellungen/:id" component={GroupSettingsPage} />
                        <Route path="/jassgruppen" component={GroupsPage} />
                        <Route path="/profile" component={ProfilePage} />
                        <Route path="/statistik" component={StatisticsPage} />
                    </>
                )}
                <Route path="/" component={LoginPage} />
            </Switch>
        </ReactRouter>
    );
};
