import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import ImageUploader from "react-images-upload";
import { Button, Card } from "react-onsenui";
import { toast } from "react-toastify";
import { IUser } from "../../../api/domain";
import { firebase, firestore, storage } from "../../../firebase";
import { UserImage, UserImageSize } from "../../user-image/user-image";
import "./user-image-setting-card.scss";

interface IOwnProps {}

interface IUserImageSettingCardProps extends IOwnProps {}

export const UserImageSettingCard: React.FC<IUserImageSettingCardProps> = () => {
  const [displayImage, setDisplayImage] = useState({} as File);
  const [isUploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [firebaseUser] = useAuthState(firebase.auth());
  const imagePath = `/users/${firebaseUser?.uid}/profilePicture`;
  const documentPath = `users/${firebaseUser?.uid}`;
  const [value] = useDocument(firestore.doc(documentPath));
  let user: IUser | undefined = value?.data() as IUser;

  const onDrop = (dropedFiles: File[]) => {
    if (!dropedFiles || !dropedFiles.length) {
      return;
    }
    setDisplayImage(dropedFiles[0]);
  };

  const uploadImage = () => {
    setUploadProgress(0);
    setUploading(true);
    const uploadTask = storage.ref(imagePath).put(displayImage);
    uploadTask.on(
      "state_changed",
      (snapShot) => setUploadProgress(snapShot.bytesTransferred),
      (err) => {
        toast.error("Anzeigebild konnte nicht geändert werden!");
        console.error(err);
        setUploading(false);
      },
      () => {
        toast.success("Anzeigebild erfolgreich geändert!");
        setUploading(false);
      }
    );
  };

  return (
    <Card>
      {isUploading && (
        <Container className="d-flex justify-content-center">
          <CircularProgressbar
            className="user-image-setting-card__circular-progressbar my-4"
            value={uploadProgress}
            maxValue={displayImage && displayImage.size}
            text="Lade hoch..."
          />
        </Container>
      )}
      {!isUploading && (
        <>
          <div className="d-flex justify-content-center">
            <Form.Group>
              <Form.Label className="font-weight-bold">Anzeigebild:</Form.Label>
              <div className="d-flex align-items-center justify-content-between">
                {!isUploading && user && (
                  <div className="pr-4">
                    <UserImage
                      user={user}
                      size={UserImageSize.SMALL}
                      rounded={true}
                    />
                  </div>
                )}
                {!isUploading && (
                  <ImageUploader
                    withIcon={true}
                    onChange={onDrop}
                    buttonText="Anzeigebild auswählen"
                    fileSizeError="Die Datei ist zu gross"
                    fileTypeError="Der gewählte Dateityp ist nicht erlaubt"
                    imgExtension={[".jpg", ".gif", ".png"]}
                    maxFileSize={0.8 * 1024 * 1024}
                    withPreview={true}
                    singleImage={true}
                    withLabel={false}
                  />
                )}
              </div>
            </Form.Group>
          </div>
        </>
      )}
      <Button
        modifier="large--cta"
        onClick={uploadImage}
        disabled={!displayImage.size || isUploading}
      >
        Speichern
      </Button>
    </Card>
  );
};
