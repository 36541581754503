import React from "react";
import { Container } from "react-bootstrap";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Skeleton from "react-loading-skeleton";
import { Card, Icon, ListTitle, ToolbarButton } from "react-onsenui";
import { collect, useProps, WithStoreProp } from "react-recollect";
import { useHistory } from "react-router";
import { IGame } from "../../api/domain/game";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { SchieberService } from "../../api/service/schieber-service";
import { ActiveGroupSettingCard } from "../../components/cards/active-group-setting-card/active-group-setting-card";
import { CoiffeurCard } from "../../components/cards/coiffeur-card/coiffeur-card";
import { SchieberCard } from "../../components/cards/schieber-card/schieber-card";
import { Page } from "../../components/page/page";
import { UserImage, UserImageSize } from "../../components/user-image/user-image";
import { useUser } from "../../hooks/use-user";
import "./welcome-page.scss";

interface IOwnProps {}

interface IWelcomePageProps extends IOwnProps, WithStoreProp {}

const InternalWelcomePage: React.FC<IWelcomePageProps> = ({ store }) => {
    const toggleMenu = () => (store.isMenuOpen = !store.isMenuOpen);
    const [user, loading, , fetchUser] = useUser();
    useProps([store.isMenuOpen]);
    const history = useHistory();
    const [latestCoiffeurGames, loadingCoiffeurGames] = useCollectionData<IGame & { type: "coiffeur" | "schieber" }>(
        CoiffeurService.getLatestCoiffeurGamesQuery(3)
    );
    const [latestSchieberGames, loadingSchieberGames] = useCollectionData<IGame & { type: "coiffeur" | "schieber" }>(
        SchieberService.getLatestSchieberGamesQuery(3)
    );
    const hasGames =
        (!loadingCoiffeurGames && latestCoiffeurGames && latestCoiffeurGames.length > 0) ||
        (!loadingSchieberGames && latestSchieberGames && latestSchieberGames.length > 0);
    const allGames = (latestCoiffeurGames || [])
        .map((c) => ({
            ...c,
            type: "coiffeur",
        }))
        .concat(
            (latestSchieberGames || []).map((s) => ({
                ...s,
                type: "schieber",
            }))
        )
        .sort((g1, g2) => g2.date.toMillis() - g1.date.toMillis())
        .slice(0, 5);

    return (
        <Page fixedPull={true} classNamePullHook="text-white" onUpdate={fetchUser} hideToolbar={true} title="Jassgott">
            <div className="madras">
                <div className="left pb-5 pt-2">
                    <ToolbarButton onClick={toggleMenu}>
                        <Icon icon="md-menu" className="text-white" />
                    </ToolbarButton>
                </div>
                <div className="py-4" />
            </div>
            <div className="d-flex justify-content-center position-relative">
                {
                    <UserImage
                        history={history}
                        openProfileOnClick={true}
                        className="rounded-circle mt--5 welcome-page__user-image"
                        user={user}
                        size={UserImageSize.SMALL}
                        rounded={true}
                    />
                }
            </div>
            <h3 className="font-weight-bold h3 text-center">
                {(!loading && user && user.displayName) || <Skeleton />}
            </h3>
            <Container className="pt-2">
                <ActiveGroupSettingCard />
            </Container>
            {!hasGames && (
                <div className="pt-3">
                    <h3 className="font-weight-bold h3 text-center">Letzte Spiele</h3>
                </div>
            )}
            <Container>
                {loadingCoiffeurGames && (
                    <>
                        <Card>
                            <ListTitle>{loading || <Skeleton count={2} />}</ListTitle>
                        </Card>
                        <Card>
                            <ListTitle>{loading || <Skeleton count={2} />}</ListTitle>
                        </Card>
                    </>
                )}
                {allGames &&
                    allGames.map((game) =>
                        game.type === "coiffeur" ? (
                            <CoiffeurCard
                                onClick={() => history.push(`/coiffeur/${game.uid}`)}
                                key={game.uid}
                                game={game}
                            />
                        ) : (
                            <SchieberCard
                                onClick={() => history.push(`/schieber/${game.uid}`)}
                                key={game.uid}
                                game={game}
                            />
                        )
                    )}
            </Container>
        </Page>
    );
};

export const WelcomePage = collect(InternalWelcomePage);
