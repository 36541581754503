import { IUser } from "../../api/domain";
import { ICoiffeurConfiguration } from "../../api/domain/coiffeur-configuration";
import { IResolvedGroup } from "../../api/domain/group";
import { CoiffeurService } from "../../api/service/coiffeur-service";
import { NotificationService } from "../../api/service/notification-service";

export const createCoiffeur = async (
    group: IResolvedGroup,
    teams: IUser[][],
    configuration: ICoiffeurConfiguration
) => {
    try {
        const coiffeurId = await CoiffeurService.createCoiffeur(group, teams, configuration);
        NotificationService.success("Coiffeur wurde gestartet");
        return coiffeurId;
    } catch (e) {
        console.debug("Could not create Coiffeur");
        console.debug(e);
        NotificationService.error("Coiffeur konnte nicht gestartet werden");
    }
    return null;
};
