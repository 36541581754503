import classNames from "classnames";
import React from "react";
import "./slate-category-metadata-block.scss";
import { ICoiffeur } from "../../../../api/domain/coiffeur";
import { formatTimestampDate, formatTimestampTime } from "../../../../util/date-helpers";
import { CoiffeurLogModal } from "../../../coiffeur-log-modal/coiffeur-log-modal";

interface IOwnProps {
    className?: string;
    coiffeur: ICoiffeur;
}

interface ISlateResultMetadataBlockProps extends IOwnProps {}

export const SlateCategoryMetadataBlock: React.FC<ISlateResultMetadataBlockProps> = ({ className, coiffeur }) => {
    const [isLogOpen, setIsOpen] = React.useState(false);

    return (
        <>
            <div className={classNames("slate-result-metadata-block", className)} onClick={() => setIsOpen(true)}>
                <div className="d-flex flex-column text-uppercase font-weight-bold p-3">
                    <span className="text-center">Coiffeur</span>
                    <span className="text-center">{formatTimestampDate(coiffeur.date)}</span>
                    <span className="text-center">{formatTimestampTime(coiffeur.date)}</span>
                </div>
            </div>
            <CoiffeurLogModal
                isOpen={isLogOpen}
                onClose={() => setIsOpen(false)}
                coiffeurId={coiffeur.uid}
                groupId={coiffeur.groupId}
            />
        </>
    );
};
