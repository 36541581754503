import classNames from "classnames";
import React, { useMemo } from "react";
import { IResult } from "../../../api/domain/result";
import { UserImage, UserImageSize } from "../../user-image/user-image";
import { TeamPosition } from "../slate-result-column/slate-result-column";
import "./slate-result-block.scss";

interface IOwnProps {
    className?: string;
    onClick?: () => void;
    position: TeamPosition;
    result?: IResult;
    match?: boolean;
}

interface ISlateResultBlockProps extends IOwnProps {}

const splitNumber = (num?: number, hideZeros?: boolean) =>
    num === undefined || (!num && hideZeros)
        ? ["", ""]
        : num < 100
        ? num.toLocaleString(undefined, { minimumIntegerDigits: 2 }).split("")
        : [`${num}`, ""];
const getDigits = (left: boolean, result?: IResult) =>
    left ? splitNumber(result?.points, false) : splitNumber(result?.score, true);

export const SlateResultBlock: React.FC<ISlateResultBlockProps> = ({ className, onClick, position, result }) => {
    const isLeft = position === TeamPosition.LEFT;

    const leftDigits = useMemo(() => getDigits(isLeft, result), [
        result,
        // eslint-disable-next-line
        result?.points,
        // eslint-disable-next-line
        result?.score,
        isLeft,
    ]);
    const rightDigits = useMemo(() => getDigits(!isLeft, result), [
        result,
        // eslint-disable-next-line
        result?.points,
        // eslint-disable-next-line
        result?.score,
        isLeft,
    ]);
    const strikeThroughRight = result?.lostOrTied && position === TeamPosition.LEFT;
    const strikeThroughLeft = result?.lostOrTied && position === TeamPosition.RIGHT;

    return (
        <div onClick={onClick} className={classNames(className, "slate-result-block")}>
            <div className="slate-result-block__numbers font-chalk d-flex h-100 position-relative">
                <div
                    className={classNames(
                        "border-right border-white w-25 h-100 d-flex ",
                        leftDigits[0].length > 1 ? "pl-1" : "justify-content-center"
                    )}
                >
                    {leftDigits[0]}
                </div>
                <div className="border-right border-white border-2 w-25 h-100 d-flex justify-content-center position-relative">
                    {leftDigits[1]}
                    {isLeft && (result?.match || result?.counterMatch) && (
                        <div className="slate-result-block__match-indicator">
                            <UserImage
                                classNameImage={classNames("border", result.match ? "border-success" : "border-danger")}
                                user={result.player}
                                size={UserImageSize.TINY}
                                rounded={true}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={classNames(
                        "border-right border-white w-25 h-100 d-flex ",
                        rightDigits[0].length > 1 ? "pl-1" : "justify-content-center"
                    )}
                >
                    {rightDigits[0]}
                </div>
                <div className="w-25 h-100 d-flex justify-content-center position-relative">
                    {rightDigits[1]}
                    {!isLeft && (result?.match || result?.counterMatch) && (
                        <div className="slate-result-block__match-indicator">
                            <UserImage
                                classNameImage={classNames("border", result.match ? "border-success" : "border-danger")}
                                user={result.player}
                                size={UserImageSize.TINY}
                                rounded={true}
                            />
                        </div>
                    )}
                </div>
                {(strikeThroughRight || strikeThroughLeft) && (
                    <div
                        className={classNames(
                            "slate-result-block__strike-wrapper position-absolute",
                            strikeThroughRight && "slate-result-block__strike-wrapper--right"
                        )}
                    >
                        <div className="slate-result-block__strike bg-chalk" />
                    </div>
                )}
            </div>
        </div>
    );
};
