import classNames from "classnames";
import React from "react";
import "./progress-indicator.scss";

interface IOwnProps {
    className?: string;
}

interface IProgressIndicatorProps extends IOwnProps {}

export const ProgressIndicator: React.FC<IProgressIndicatorProps> = ({ className }) => {
    return (
        <div className={classNames(className, "progress-indicator")}>
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};
