import React, { useEffect, useState } from "react";
import { List, ListHeader, ProgressBar } from "react-onsenui";
import { collect, WithStoreProp } from "react-recollect";
import { useParams } from "react-router";
import { IResolvedGroup } from "../../../api/domain/group";
import { GroupService } from "../../../api/service/group-service";
import { UserListItem } from "../../../components/list-items/user-list-item/user-list-item";
import { Page } from "../../../components/page/page";
import { CoiffeurSettings } from "../../../components/settings/coiffeur-settings/coiffeur-settings";
import { GroupNameSettings } from "../../../components/settings/group-name-settings/group-name-settings";
import { SchieberSettings } from "../../../components/settings/schieber-settings/schieber-settings";
import "./group-settings-page.scss";

interface IOwnProps {}

interface IGroupSettingsPageProps extends IOwnProps, WithStoreProp {}

export const InternalGroupSettingsPage: React.FC<IGroupSettingsPageProps> = () => {
    let { id } = useParams<{ id: string }>();
    const [group, setGroup] = useState<IResolvedGroup | undefined>();
    useEffect(() => {
        GroupService.getGroup(`${id}`).then(setGroup);
    }, [id]);
    return (
        <Page title="Gruppen Einstellungen">
            {!group && <ProgressBar indeterminate={true} />}
            <List>
                {group && <GroupNameSettings groupId={group.uid} />}
                {group && <SchieberSettings groupId={group.uid} />}
                {group && <CoiffeurSettings groupId={group.uid} />}
                <ListHeader>Mitglieder</ListHeader>
                {group?.members?.map((member) => member && <UserListItem key={member.uid} user={member} />)}
            </List>
        </Page>
    );
};

export const GroupSettingsPage = collect(InternalGroupSettingsPage);
