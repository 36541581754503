import React, { useState } from "react";
import { Button } from "react-onsenui";
import { Page } from "../../components/page/page";
import "./jasskasse.scss";

interface IOwnProps {
    className?: string;
}

interface IJasskasseProps extends IOwnProps {}

export const Jasskasse: React.FC<IJasskasseProps> = ({ className }) => {
    const [refreshKey, setRefreshKey] = useState(1);
    const onUpdate = async () => setRefreshKey(refreshKey + 1);

    return (
        <Page key={refreshKey} onUpdate={onUpdate} title="Jasskasse">
            <div className="p-4 bg-slate d-flex justify-content-center align-items-center">
                <Button onClick={onUpdate}>Aktualisieren</Button>
            </div>
            <div className="w-100 h-100">
                <iframe title="Jasskasse" className="w-100 h-100 border-0" src="https://www.jasskasse.ch" />
            </div>
        </Page>
    );
};
