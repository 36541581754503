import firebase from "firebase";
import { IUser } from "./user";

export enum LogEventType {
    COIFFEUR_STARTED = "COIFFEUR_STARTED",
    RESULT_ADDED = "RESULT_ADDED",
    RESULT_DELETED = "RESULT_DELETED",
}

type GeneralStoredLogEvent = {
    date: firebase.firestore.Timestamp;
    loggedByUserId: string;
    uid: string;
};

export type CoiffeurStartedLogEvent = {
    type: LogEventType.COIFFEUR_STARTED;
    coiffeurId: string;
};

export type ResultAddedLogEvent = {
    type: LogEventType.RESULT_ADDED;
    coiffeurId: string;
    disciplineId: string;
    player: IUser;
    match: boolean;
    teamId: string;
    points: number;
};

export type ResultDeletedGameLogEvent = {
    type: LogEventType.RESULT_DELETED;
    coiffeurId: string;
    disciplineId: string;
};

export type LogEvent = ResultAddedLogEvent | ResultDeletedGameLogEvent | CoiffeurStartedLogEvent;
export type StoredLogEvent = GeneralStoredLogEvent & LogEvent;
