import classNames from "classnames";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Card, Icon } from "react-onsenui";
import { useHistory } from "react-router";
import { IUser } from "../../../api/domain";
import { ISchieberWithTeams } from "../../../api/domain/schieber";
import { formatTimestampDate } from "../../../util/date-helpers";
import { UserImage, UserImageSize } from "../../user-image/user-image";
import "./schieber-list-item.scss";

interface IOwnProps {
    className?: string;
    schieber: ISchieberWithTeams;
    deleteSchieber: (schieberId: string) => void;
}

interface ISchieberListItemProps extends IOwnProps {}

const renderTeam = (team: IUser[], winner: boolean) => {
    const color = winner ? `rgba(89,255,0, 1` : `rgba(255,0,0, 1`;

    return (
        <Row className="p-2">
            <Col>
                {team.map((user) => (
                    <div key={user.uid} className="d-flex align-items-center">
                        <UserImage
                            innerStyle={{ border: `1px solid ${color}`, color }}
                            classNameImage="border-1"
                            size={UserImageSize.EXTRA_SMALL}
                            user={user}
                            rounded={true}
                        />
                        <span className="pl-3 font-crayon text-white">{user.displayName}</span>
                    </div>
                ))}
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
                <div className="w-50 d-flex justify-content-end align-items-center">
                    <Icon style={{ color }} icon={winner ? "fa-thumbs-up" : "fa-thumbs-down"} />
                </div>
            </Col>
        </Row>
    );
};

export const SchieberListItem: React.FC<ISchieberListItemProps> = ({ className, schieber, deleteSchieber }) => {
    const teams: IUser[][] = useMemo(
        () => schieber.teams.map((team) => Object.keys(team.members).map((userId) => team.members[userId] as IUser)),
        [schieber]
    );
    const winners = schieber?.winners || {};
    const history = useHistory();
    const onDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        deleteSchieber(schieber.uid);
    };

    return (
        <Card className={classNames(className, "schieber-list-item border-bottom bg-slate text-white")}>
            <div onClick={() => history.push(`/schieber/${schieber.uid}`)}>
                <div className="d-flex justify-content-between align-items-center font-weight-bold font-crayon">
                    {formatTimestampDate(schieber.date)}
                    <div className="p-1 px-2" onClick={onDelete}>
                        <Icon className="text-white text-1-4" icon="md-delete" />
                    </div>
                </div>
                {renderTeam(teams[0], !!winners[teams[0][0].uid])}
                {renderTeam(teams[1], !!winners[teams[1][0].uid])}
            </div>
        </Card>
    );
};
