import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton/lib";
import { Icon, ToolbarButton } from "react-onsenui";
import { collect, useProps, WithStoreProp } from "react-recollect";
import { useParams } from "react-router";
import { IUser } from "../../api/domain";
import { UserService } from "../../api/service/user-service";
import { LatestActivityList } from "../../components/latest-activity-list/latest-activity-list";
import { Page } from "../../components/page/page";
import { UserImage, UserImageSize } from "../../components/user-image/user-image";
import "./public-profile-page.scss";

interface IOwnProps {}

interface IPublicProfilePageProps extends IOwnProps, WithStoreProp {}

const InternalPublicProfilePage: React.FC<IPublicProfilePageProps> = ({ store }) => {
    const toggleMenu = () => (store.isMenuOpen = !store.isMenuOpen);
    let { id } = useParams<{ id: string }>();
    const [user, setUser] = useState<IUser>();
    const [loading, setLoading] = useState(false);
    const fetchUser = async () => {
        setLoading(true);
        UserService.getUserById(id || "")
            .then(setUser)
            .finally(() => {
                setLoading(false);
            });
    };
    useProps([store.isLoading]);
    useEffect(() => {
        store.isLoading = loading;
        // eslint-disable-next-line
    }, [loading]);
    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line
    }, [id]);
    return (
        <Page fixedPull={true} classNamePullHook="text-white" onUpdate={fetchUser} hideToolbar={true} title="Jassgott">
            <div className="bg-brady-bunch">
                <div className="left pb-5 pt-2">
                    <ToolbarButton onClick={toggleMenu}>
                        <Icon icon="md-menu" className="text-white" />
                    </ToolbarButton>
                </div>
                <div className="py-4" />
            </div>
            <div className="d-flex justify-content-center position-relative">
                {
                    <UserImage
                        className="rounded-circle mt--5 welcome-page__user-image"
                        user={user}
                        size={UserImageSize.SMALL}
                        rounded={true}
                    />
                }
            </div>
            <h3 className="font-weight-bold h3 px-4 text-center">{(user && user.displayName) || <Skeleton />}</h3>
            <div>{user && <LatestActivityList user={user} />}</div>
        </Page>
    );
};

export const PublicProfilePage = collect(InternalPublicProfilePage);
