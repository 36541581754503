import React, { useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Icon, List, ListHeader, ProgressBar } from "react-onsenui";
import { IGroupCoiffeurSettings } from "../../../api/domain/group-settings";
import { firestore } from "../../../firebase";
import CoiffeurTafel from "../../../img/slate_coiffeur.png";
import { updateCoiffeurSettings } from "../../../store/updaters/updateSettings";
import { InputListItem } from "../../list-items/input-list-item/input-list-item";
import "./coiffeur-settings.scss";
import { Alert } from "react-bootstrap";

interface IOwnProps {
  className?: string;
  groupId: string;
}

interface ICoiffeurSettingsProps extends IOwnProps {}

const getSettingsDocumentReference = (groupId: string) =>
  firestore
    .collection("groups")
    .doc(`${groupId}`)
    .collection("settings")
    .doc("coiffeur");

export const CoiffeurSettings: React.FC<ICoiffeurSettingsProps> = ({
  groupId,
}) => {
  const doc = getSettingsDocumentReference(groupId);
  const [settings, loading] = useDocumentData<IGroupCoiffeurSettings>(doc);
  const [updating, setUpdating] = useState(false);
  const updateSettings = async (
    updatedSettings: Partial<IGroupCoiffeurSettings>
  ) => {
    setUpdating(true);
    await updateCoiffeurSettings(updatedSettings, groupId);
    setUpdating(false);
  };
  const saveCostsPerMark = (costsPerMark: number) =>
    updateSettings({ costsPerMark });
  const saveDisciplineVictory = (disciplineVictory: number) =>
    updateSettings({ disciplineVictory });
  const saveMatch = (match: number) => updateSettings({ match });
  const saveCounterMatch = (counterMatch: number) =>
    updateSettings({ counterMatch });
  const saveVictory = (victory: number) => updateSettings({ victory });
  const saveMatchBonusPoints = (matchBonusPoints: number) =>
    updateSettings({ matchBonusPoints });

  return loading ? (
    <ProgressBar indeterminate={true} />
  ) : (
    <List>
      <ListHeader>
        <img
          alt="Coiffeur Jasstafel"
          className="mr-3"
          width={30}
          src={CoiffeurTafel}
        />
        Coiffeur
      </ListHeader>
      <InputListItem
        disabled={updating}
        value={settings?.costsPerMark || 0}
        label="Kosten pro Strich"
        type="number"
        icon={<Icon icon="fa-coins" />}
        onSave={saveCostsPerMark}
        decimal={true}
        unit="CHF"
      />
      <InputListItem
        disabled={updating}
        value={settings?.disciplineVictory || 0}
        onSave={saveDisciplineVictory}
        label="Kleiner Sieg (Kategorie)"
        type="number"
        icon={<Icon icon="fa-medal" />}
        unit="Striche"
      />
      <InputListItem
        disabled={updating}
        value={settings?.match || 0}
        onSave={saveMatch}
        label="Match"
        type="number"
        icon={<Icon icon="fa-thumbs-up" />}
        unit="Striche"
      />
      <InputListItem
        disabled={updating}
        value={settings?.counterMatch || 0}
        onSave={saveCounterMatch}
        label="Kontermatch"
        type="number"
        icon={<Icon icon="fa-thumbs-down" />}
        unit="Striche"
      />
      <InputListItem
        disabled={updating}
        value={settings?.victory || 0}
        onSave={saveVictory}
        label="Gesamtsieg"
        type="number"
        icon={<Icon icon="fa-trophy" />}
        unit="Striche"
      />
      <InputListItem
        disabled={updating}
        value={settings?.matchBonusPoints || 0}
        onSave={saveMatchBonusPoints}
        label="Match Bonuspunkte"
        type="number"
        icon={<Icon icon="fa-plus" />}
        unit="Punkte"
      />
      <Alert variant="info" className="d-flex">
        <div className="p-3">
          <Icon icon="md-info" />
        </div>
        <div>
          Match Bonuspunkte werden nach der Berechnung der Differenz zur
          Gesamtpunktzahl addiert. Die Punkte werden jeweils gerundet und als
          Zehntel aufgeschrieben. 10 gerundete Punkte entsprechen also
          ungerundeten 100 Punkten.
        </div>
      </Alert>
    </List>
  );
};
