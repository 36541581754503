import classNames from "classnames";
import React from "react";
import "./splash-screen.scss";
import Baur from "../../img/baur.png";

interface IOwnProps {
  className?: string;
}

interface ISplashScreenProps extends IOwnProps {}

export const SplashScreen: React.FC<ISplashScreenProps> = ({ className }) => {
  return (
    <div className={classNames(className, "splash-screen")}>
      <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
        <div>
          <div className="d-flex justify-content-center mt-5 border-bottom border-danger">
            <img alt="Jasskarte Baur" width={100} height={100} src={Baur} />
          </div>
          <div className="d-flex justify-content-center">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div
            style={{
              transform: "rotate(180deg)",
            }}
            className="d-flex justify-content-center border-bottom border-danger"
          >
            <img alt="Jasskarte Baur" width={100} height={100} src={Baur} />
          </div>
        </div>
      </div>
    </div>
  );
};
