import classNames from "classnames";
import React from "react";
import { IUser } from "../../../api/domain";
import { ITeam } from "../../../api/domain/team";
import { UserImage, UserImageSize } from "../../user-image/user-image";
import "./choose-player.scss";

interface IOwnProps {
    className?: string;
    player?: IUser;
    team: ITeam;
    setPlayer: (player: IUser) => void;
}

interface IChoosePlayerProps extends IOwnProps {}

export const ChoosePlayer: React.FC<IChoosePlayerProps> = ({ className, team, setPlayer, player }) => {
    const users: IUser[] = Object.keys(team.members).map((memberId) => team.members[memberId]);

    return (
        <div
            className={classNames(
                className,
                "choose-player",
                !!player && "d-flex justify-content-center align-items-center"
            )}
        >
            {users.map((user) => (
                <div
                    key={user.uid}
                    onClick={setPlayer.bind(null, user)}
                    className={classNames(
                        "choose-player__tile border border-3 border-white m-3 p-2",
                        user === player && "choose-player__tile--selected",
                        !!player && user !== player && "choose-player__tile--unselected"
                    )}
                >
                    <UserImage
                        classNameImage="border border-white border-2"
                        size={!player ? UserImageSize.MEDIUM : UserImageSize.SMALL}
                        user={user}
                        rounded={true}
                    />
                    <div className={classNames("font-weight-bold font-crayon pt-2", !player && "h3")}>
                        {user.displayName}
                    </div>
                </div>
            ))}
        </div>
    );
};
