import React, { useMemo, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { Icon, ListItem } from "react-onsenui";
import "./input-list-item.scss";

interface IOwnProps {
  className?: string;
  unit?: string;
  onSave: (value: number) => void;
  type: string;
  icon: JSX.Element;
  label: string;
  value: number;
  decimal?: boolean;
  disabled?: boolean;
}

interface IInputListItemProps extends IOwnProps {}

export const InputListItem: React.FC<IInputListItemProps> = ({
  icon,
  label,
  unit,
  type,
  value,
  onSave,
  decimal,
  disabled,
}) => {
  const [internalValue, setInternalValue] = useState<string>(`${value}`);
  useMemo(
    () =>
      setInternalValue(decimal ? value.toFixed(2) : value.toString() || "0"),
    [value, decimal]
  );
  const onInternalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = decimal
      ? event.target.value
      : parseInt(event.target.value).toString() || "0";
    setInternalValue(val);
  };
  const formatInput = () =>
    decimal &&
    setInternalValue(
      decimal
        ? parseFloat(internalValue).toFixed(2)
        : parseFloat(internalValue).toFixed(0)
    );
  const saveDisabled = parseFloat(internalValue) === value || disabled;

  return (
    <ListItem className="input-list-item">
      <InputGroup>
        <InputGroup.Prepend className="input-list-item__label">
          <InputGroup.Text className="w-100 text-wrap">
            <span className="pr-2">{icon}</span>
            {label}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          className="input-list-item__input h-auto"
          onChange={onInternalChange}
          onBlur={formatInput}
          value={internalValue}
          type={type}
        />
        <InputGroup.Append>
          {unit && (
            <InputGroup.Text className="input-list-item__unit d-flex justify-content-center align-items-center">
              {unit}
            </InputGroup.Text>
          )}
          <Button
            onClick={onSave.bind(null, parseFloat(internalValue))}
            disabled={saveDisabled}
            variant={!saveDisabled ? "success" : "secondary"}
          >
            <Icon icon="md-save" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </ListItem>
  );
};
