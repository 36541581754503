import classNames from "classnames";
import React from "react";
import { ITeam } from "../../../api/domain/team";
import "./schieber-slate-click-pane.scss";

interface IOwnProps {
    className?: string;
    team: ITeam;
    onClick?: (userIds: string[]) => void;
}

interface ISchieberSlateClickPaneProps extends IOwnProps {}

export const SchieberSlateClickPane: React.FC<ISchieberSlateClickPaneProps> = ({
    className,
    team,
    onClick = () => undefined,
    children,
}) => {
    const userIds: string[] = Object.keys(team.members).map((memberId) => team.members[memberId].uid);

    return (
        <div onClick={onClick.bind(null, userIds)} className={classNames(className, "schieber-slate-click-pane")}>
            {children}
        </div>
    );
};
