import { toast } from "react-toastify";

export class NotificationService {
    static success(message: string) {
        toast.success(message);
    }

    static successTop(message: string) {
        toast.success(message, { position: "top-right", pauseOnHover: false });
    }

    static info(message: string) {
        toast.info(message);
    }

    static infoTop(message: string) {
        toast.info(message, { position: "top-right", pauseOnHover: false });
    }

    static error(message: string) {
        toast.error(message);
    }

    static errorTop(message: string) {
        toast.error(message, { position: "top-right", pauseOnHover: false });
    }

    static warn(message: string) {
        toast.warn(message);
    }
}
